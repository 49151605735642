import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";

import image from "../../assets/images/thumbnails/avatar-2.jpg";
import { Text } from "../Text";

export const ProfileCard = () => {
  return (
    <Container>
      <Border>
        <Avatar />
      </Border>

      <Text size="xl" variant="titleText">
        Jiro Ricaro
      </Text>
      <Text size="sm" variant="subTitleText">
        <b>
          <Typewriter
            options={{
              strings: [
                "Software Engineer",
                "UI/UX Enthusiast",
                "Gaming Expert",
                "Comics Geek",
                "Freediver",
                "Rider"
              ],
              autoStart: true,
              loop: true,
              deleteSpeed: 20,
              delay: 40,
            }}
          />
        </b>
      </Text>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  height: auto;
  margin-top: 56px;
  padding: 56px 16px 16px 16px;
  background-color: ${(props) => props.theme.body};
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.boxShadowEngraved};

  & > p {
    text-align: center;
  }
`;

const Avatar = styled.div`
  height: 80px;
  width: 80px;
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 50%;

  background-image: url(${image});
  background-size: 100% 100%;
`;

const Border = styled.div`
  position: absolute;
  top: -56px;
  z-index: 99999;

  padding: 16px;
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  background-color: ${(props) => props.theme.body};
  border-radius: 50%;
`;
