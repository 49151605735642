import React from "react";
import styled from "styled-components";

import { device } from "../../device";

import { Hamburger, Logo } from "../../components/Navigation";
import { ThemeSwitcher } from "../../components/ThemeSwitcher";
import { DesktopMenu } from "./Menu";

export const Header = (props) => {
  return (
    <Container>
      <Hamburger onClick={props.openNav} />
      <Logo />
      <DesktopMenu />
      <ThemeSwitcher
        isToggled={props.isToggled}
        onClick={props.themeSwitch}
      ></ThemeSwitcher>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 16px 24px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowBody};
  margin-bottom: 8px;

  @media ${device.tablet} {
    padding: 24px 16px;
    margin-bottom: 32px;
  }

  @media ${device.laptop} {
    padding: 16px;
    margin-bottom: 12px;
    justify-content: space-evenly;
  }
`;
