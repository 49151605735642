import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { NavLink } from "react-router-dom";

export const MobileMenu = (props) => {
  const themeContext = useContext(ThemeContext);

  const defaultStyle = {
    position: "relative",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "8px",
    padding: "12px 24px",
    backgroundColor: themeContext.body,
    color: themeContext.buttonText,
    boxShadow: themeContext.boxShadowEmbossed,
    border: "none",
    outline: "none",
    maxHeight: "40px",
    width: "100%",

    fontSize: "0.875rem",
    fontWeight: "700",
    textAlign: "center",
    textDecoration: "none",
    textTransform: "uppercase",

    cursor: "pointer",
  };

  const isActive = {
    boxShadow: themeContext.boxShadowEngraved,
    color: themeContext.buttonTextActive,
  };

  return (
    <Container>
      <NavLink
        to="/"
        exact
        style={defaultStyle}
        activeStyle={isActive}
        onClick={props.navClicked}
      >
        PROFILE
      </NavLink>
      <NavLink
        to="/about"
        style={defaultStyle}
        activeStyle={isActive}
        onClick={props.navClicked}
      >
        ABOUT
      </NavLink>
      <NavLink
        to="/portfolio"
        style={defaultStyle}
        activeStyle={isActive}
        onClick={props.navClicked}
      >
        PORTFOLIO
      </NavLink>
      <NavLink
        to="/contact"
        style={defaultStyle}
        activeStyle={isActive}
        onClick={props.navClicked}
      >
        CONTACT
      </NavLink>
    </Container>
  );
};

const Container = styled.nav`
  position: relative;
  z-index: 10000;

  display: flex;
  flex-flow: column;
  margin-top: 32px;
  width: 100%;

  & > * {
    margin-bottom: 16px;
  }
`;
