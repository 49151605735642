import React from "react";
import styled from "styled-components";

import { device } from "../../device";
import { Card } from "../../components/Card";

export const PortfolioContainer = (props) => {
  const PortfolioItems = props.portfolioHighlights.map((card) => {
    return (
      <Card
        image={card.image}
        title={card.title}
        key={card.id}
        details={card.details}
        htmlLogo={card.htmlLogo}
        cssLogo={card.cssLogo}
        jsLogo={card.jsLogo}
        reactLogo={card.reactLogo}
        sassLogo={card.sassLogo}
        figmaLogo={card.figmaLogo}
        psLogo={card.psLogo}
        npmLogo={card.npmLogo}
        vsLogo={card.vsLogo}
        category={card.category}
        imageClicked={props.imageClicked.bind(this, card.id)}
        url={card.url}
      ></Card>
    );
  });

  return (
    <Border>
      <Constrainer>
        <Content>{PortfolioItems}</Content>
      </Constrainer>
    </Border>
  );
};

const Border = styled.div`
  position: relative;

  padding: 8px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 8px;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  margin-bottom: 24px;
`;

const Constrainer = styled.div`
  position: relative;

  border-radius: 8px;
  width: 100%;
  min-height: 100%;
  padding: 24px 0;
  overflow-x: auto;
  box-shadow: ${(props) => props.theme.boxShadowEngraved};

  @media ${device.tablet} {
    & > div {
      margin-right: 24px;
    }
  }
`;

const Content = styled.div`
  display: flex;
  flex-flow: row nowrap;
  min-width: 1760px;
  margin: 0 24px 0 0;
`;
