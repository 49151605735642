import React from "react";
import styled from "styled-components";

import { Text } from "../Text";
import { ContentTitle } from "../ContentTitle";
import { device } from "../../device";

export const History = (props) => {
  const careerHistory = [
    { title: "SOFTWARE ENGINEER", subTitle: "Slerp, ltd.", year: "2021-PRESENT" },
    { title: "SOFTWARE DEVELOPER", subTitle: "Mashup Technology Ventures, Inc.", year: "2021-2022" },
    {
      title: "WEB DEVELOPER",
      subTitle: "MOD2 Software Solutions, Inc.",
      year: "2019-2020",
    },
    { title: "WEB BUILDER VA", subTitle: "Luxury Presence, Inc.", year: "2018-2019" },
  ];

  const educationHistory = [
    {
      title: "BS COMPUTER SCIENCE",
      subTitle: "AMA University",
      year: "2012-2016",
    },
    {
      title: "HIGH SCHOOL DIPLOMA",
      subTitle: "San Bartolome High School",
      year: "2008-2011",
    },
  ];

  return (
    <Container>
      {props.variant === "career" && (
        <ContentTitle icon="career" text="Career History" />
      )}
      {props.variant === "education" && (
        <ContentTitle icon="education" text="Education" />
      )}

      <HistoryContainer>
        {props.variant === "career"
          ? careerHistory.map((item) => {
              return (
                <HistoryItem key={item.title}>
                  <Text size="sm" variant="careerText">
                    {item.title}
                  </Text>
                  <Text size="xs" variant="subTitleText">
                    {item.subTitle}
                    <br />
                    {item.year}
                  </Text>
                </HistoryItem>
              );
            })
          : props.variant === "education"
          ? educationHistory.map((item) => {
              return (
                <HistoryItem key={item.title}>
                  <Text size="sm" variant="educationText">
                    {item.title}
                  </Text>
                  <Text size="xs" variant="subTitleText">
                    {item.subTitle}
                    <br />
                    {item.year}
                  </Text>
                </HistoryItem>
              );
            })
          : null}
      </HistoryContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;

  & > :first-child {
    margin-top: 16px;
  }

  @media ${device.tablet} {
    max-width: 240px;
  }

  @media ${device.tablet} {
    max-width: 264px;
  }
`;

const HistoryContainer = styled.div`
  position: relative;

  display: flex;
  flex-flow: column;
  padding: 0px 16px 0 24px;
  margin: 0 0 0 8px;

  &::before {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;

    content: "";
    width: 8px;
    height: 100%;
    box-shadow: ${(props) => props.theme.boxShadowEngraved};
    border-radius: 8px;
  }
`;

const HistoryItem = styled.div`
  position: relative;
  padding: 16px 0;

  &::before {
    position: absolute;
    left: -28px;
    top: 50%;
    z-index: 2;

    content: "";
    width: 16px;
    height: 16px;
    background-color: ${(props) => props.theme.body};
    box-shadow: ${(props) => props.theme.boxShadowEngraved};
    border-radius: 50%;

    transform: translateY(-50%);
  }
`;
