import React from "react";
import styled from "styled-components";

import { JiroLogo } from "../../assets/logos";

export const Logo = () => {
  return (
    <Container>
      <JiroLogo />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border-radius: 8px;
`;
