import React from "react";
import styled from "styled-components";

import { IconContext } from "react-icons";
import { FaPhone } from "react-icons/fa";
import { RiChat3Fill } from "react-icons/ri";

export const CompactButton = (props) => {
  return (
    <IconContext.Provider value={{ size: "24px" }}>
      <StyledButton {...props}>
        {props.icon === "call" && <FaPhone />}
        {props.icon === "chat" && <RiChat3Fill />}
      </StyledButton>
    </IconContext.Provider>
  );
};

const StyledButton = styled.button`
  position: relative;

  padding: 12px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border: none;
  border-radius: 8px;
  outline: none;
  width: 64px;
  height: 56px;

  cursor: pointer;

  &:hover {
    & > * {
      transform: rotate(15deg);
    }
  }

  & > * {
    color: ${(props) => props.theme.buttonText};
    transition: transform 200ms linear;
  }

  &:active {
    box-shadow: ${(props) => props.theme.boxShadowEngraved};
    top: 4px;

    & > * {
      position: relative;
      top: 1px;
      color: ${(props) => props.theme.buttonTextActive};
    }
  }
`;
