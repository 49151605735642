import React from "react";
import styled from "styled-components";

import { device } from "../device";

export const ContentBorder = (props) => {
  return <Content>{props.children}</Content>;
};

const Content = styled.div`
  position: relative;

  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 8px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border-radius: 8px;
  overflow: hidden;

  &::before {
    position: absolute;
    top: 0px;
    left: 0px;

    content: "";
    height: 16px;
    width: 100%;
    border-radius: 2px;
    opacity: 0.15;
    box-shadow: ${(props) => props.theme.boxShadowEngraved};
  }

  & > * {
    margin-bottom: 16px;
  }

  & > :last-child {
    margin-bottom: 0px;
  }

  @media ${device.laptop} {
    flex-flow: row;
    justify-content: center;
    align-items: center;

    & > * {
      margin-right: 32px;
      margin-bottom: 24px;
    }

    & > :last-child {
      margin-right: 0px;
      margin-bottom: 0px;
    }
  }
`;
