import React, { useState, useEffect, useCallback } from "react";
import styled, { ThemeProvider } from "styled-components";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import { GlobalStyle } from "./GlobalStyle";
import { lightTheme, darkTheme } from "./components/Themes";

import { device } from "./device";
import { PortfolioData } from "./PortfolioData";

import {
  AppBody,
  PageContainer,
  ProfilePage,
  AboutPage,
  PortfolioPage,
  ContactPage,
} from "./containers";

import { Header } from "./containers/Header";
import { MobileNavigation } from "./containers/MobileNavigation";
import { FullImage } from "./components/FullImage";
import { Modal, NavModal } from "./components/Modal";

const App = (props) => {
  const stored = localStorage.getItem("isDarkMode");
  const [isDarkMode, setIsDarkMode] = useState(
    stored === "true" ? false : true
  );
  const [currentData, setCurrentData] = useState(
    PortfolioData.sort((a, b) => (a.id > b.id ? 1 : -1))
  );
  const [currentImage, setCurrentImage] = useState(null);

  const [isNavOpened, setIsNavOpened] = useState(false);
  const [isImageOpened, setIsImageOpened] = useState(false);
  const [isImageModalOpened, setIsImageModalOpened] = useState(false);
  const [isNavModalOpened, setIsNavModalOpened] = useState(false);

  const [portfolioFilter, setPortfolioFilter] = useState("all");
  const [triggerAnimationReset, setTriggerAnimationReset] = useState(false);

  const portfolioHighlights = PortfolioData.filter((card) => {
    return card.highlights === true;
  }).sort((a, b) => a.id - b.id);

  const onImageOpen = (cardId) => {
    setTriggerAnimationReset(false);
    setIsImageOpened(true);
    setIsImageModalOpened(true);

    let currentCard = currentData.filter((card) => {
      return card.id === cardId;
    });

    setCurrentImage(currentCard[0]?.fullImage);
  };

  const onImageClose = () => {
    setTriggerAnimationReset(false);
    setIsImageOpened(false);
    setIsImageModalOpened(false);

    setCurrentImage(null);
  };

  const filterAll = () => {
    const filterAll = PortfolioData.sort((a, b) => (a.id > b.id ? 1 : -1));

    setCurrentData(filterAll);
  };

  const portfolioFilterHandler = useCallback(() => {
    setTriggerAnimationReset(true);
    switch (portfolioFilter) {
      case "all":
        filterAll();
        break;

      case "code":
        const filterCode = PortfolioData.filter((card) => {
          return card.category === "code";
        });

        const sortedCode = filterCode.sort((a, b) => (a.id > b.id ? 1 : -1));

        setCurrentData(sortedCode);
        break;

      case "design":
        const filterDesign = PortfolioData.filter((card) => {
          return card.category === "design";
        });

        const sortedDesign = filterDesign.sort((a, b) =>
          a.id > b.id ? 1 : -1
        );

        setCurrentData(sortedDesign);
        break;

      default:
        filterAll();
    }
  }, [portfolioFilter]);

  useEffect(() => {
    portfolioFilterHandler();
    // eslint-disable-next-line
  }, [portfolioFilter]);

  return (
    <Router>
      <ThemeProvider theme={isDarkMode ? darkTheme : lightTheme}>
        <GlobalStyle />
        <FullImage
          clicked={onImageClose}
          displayedImage={currentImage}
          isOpened={isImageOpened}
        />
        {/* ImageModal */}
        <Modal onClick={onImageClose} modalDisplay={isImageModalOpened} />

        {/* NavModal */}
        <NavModal
          modalDisplay={isNavModalOpened}
          onClick={() => {
            setIsNavOpened(false);
            setIsNavModalOpened(false);
          }}
        />
        <StyledApp {...props}>
          <MobileNavigation
            opened={isNavOpened}
            navClicked={() => {
              setIsNavOpened(false);
              setIsNavModalOpened(false);
            }}
          />

          <Header
            isToggled={isDarkMode}
            themeSwitch={() => {
              setIsDarkMode(!isDarkMode);
              localStorage.setItem("isDarkMode", isDarkMode);
            }}
            openNav={() => {
              setIsNavOpened(true);
              setIsNavModalOpened(true);
            }}
          />
          <AppBody>
            <PageContainer>
              <Switch>
                <Route
                  path="/"
                  exact
                  render={() => (
                    <ProfilePage
                      portfolioHighlights={portfolioHighlights}
                      imageClicked={onImageOpen}
                    />
                  )}
                />
                <Route path="/about" render={() => <AboutPage />} />
                <Route
                  path="/portfolio"
                  render={() => (
                    <PortfolioPage
                      imageClicked={onImageOpen}
                      currentData={currentData}
                      setPortfolioFilter={setPortfolioFilter}
                      triggerAnimationReset={triggerAnimationReset}
                    />
                  )}
                />
                <Route path="/contact" render={() => <ContactPage />} />
              </Switch>
            </PageContainer>
          </AppBody>
        </StyledApp>
      </ThemeProvider>
    </Router>
  );
};

const StyledApp = styled.div`
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow-y: hidden;
  padding: 4px;

  @media ${device.tablet} {
    padding: 24px;
  }

  @media ${device.laptop} {
    padding: 24px;
    max-width: 968px;
    max-height: 720px;
    border-radius: 8px;
  }

  @media ${device.desktopM} {
    max-width: 1080px;
    max-height: 880px;
  }
`;

export default App;
