import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { sharedStyles as Styles } from "../../../components/Themes/Themes";
import { device } from "../../../device";

import { CompactButton } from "../../../components/CompactButton";
import { Button } from "../../../components/Button";
import { Text } from "../../../components/Text/";

export const ButtonsPanel = () => {
  return (
    <Container>
      <Title>
        <Separator />
        <Text variant="subtitleText" size="md">
          Let's Talk!
        </Text>
        <Separator />
      </Title>

      <ButtonsContainer>
        <CompactButtons>
          <Link
            to={{ pathname: "https://www.messenger.com/t/100065653414257" }}
            target="_blank"
          >
            <CompactButton icon="chat" />
          </Link>

          <Link to={{ pathname: "tel:+639216918001" }} target="_blank">
            <CompactButton icon="call" />
          </Link>
        </CompactButtons>
        <Link
          to={{ pathname: "mailto:developer@jiroricaro.com" }}
          target="_blank"
          style={{ textDecoration: "none" }}
        >
          <Button>Request Resume</Button>
        </Link>
      </ButtonsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-evenly;
  margin-top: 40px;

  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  padding: 32px;
  border-radius: 8px;
  width: 100%;
  max-width: 240px;
  max-height: 240px;

  @media ${device.tablet} {
    margin-top: 56px;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 144px;

  > :last-child > * {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
    font-size: 0.75rem;
    max-width: 144px;
    min-width: 144px;
    padding: 20px 16px;
  }
`;

const CompactButtons = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

const Title = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  max-width: 200px;
  margin: 0 0 24px 0;

  font-size: ${Styles.fontSizes.sm};
  font-weight: ${Styles.fontWeight.bold};
  line-height: 1.5;
  text-transform: capitalize;
  text-align: center;
`;

const Separator = styled.div`
  height: 24px;
  width: 16px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
`;
