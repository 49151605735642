import React from "react";
import styled from "styled-components";
import { useTrail, animated } from "react-spring";

import { Card, Filter } from "../../components/";
import { PageTitle } from "../../components/PageTitle";

import { device } from "../../device";

export const PortfolioPage = (props) => {
  const portfolioItems = props.currentData.map((card, index) => {
    return (
      <Card
        image={card.image}
        title={card.title}
        key={`${card.id}${index}`}
        details={card.details}
        htmlLogo={card.htmlLogo}
        cssLogo={card.cssLogo}
        jsLogo={card.jsLogo}
        reactLogo={card.reactLogo}
        sassLogo={card.sassLogo}
        figmaLogo={card.figmaLogo}
        psLogo={card.psLogo}
        npmLogo={card.npmLogo}
        vsLogo={card.vsLogo}
        category={card.category}
        imageClicked={props.imageClicked.bind(this, card.id)}
        url={card.url}
      />
    );
  });

  return (
    <Container>
      <PageTitle>Portfolio</PageTitle>
      <Filter
        setPortfolioFilter={props.setPortfolioFilter}
      />
      <AlignmentContainer>
        <Trail triggerAnimationReset={props.triggerAnimationReset}>{portfolioItems}</Trail>
      </AlignmentContainer>
    </Container>
  );
};

const Trail = (props) => {
  const items = React.Children.toArray(props.children)
  const trail = useTrail(items.length, {
    config: { mass: 1, tension: 300, friction: 25 },
    from: { transform: "translateX(-64px)", opacity: 0 },
    to: { transform: "translateX(16px)", opacity: 1 },
    reset: props.triggerAnimationReset
  });

  return (
    <CardContainer>
      {trail.map(({ ...style }, index) => (
        <animated.div key={index} style={style}>
          <animated.div>{items[index]}</animated.div>
        </animated.div>
      ))}
    </CardContainer>
  )
}

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  margin-top: 40px;

  @media ${device.tablet} {
    align-items: flex-start;
    padding: 24px 16px 24px 0;
    margin-top: 0;
  }
`;

const AlignmentContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const CardContainer = styled(animated.div)`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(256px, 1fr));
  overflow-x: hidden;
  padding-right: 24px;

  height: 100%;
  width: 100%;
  justify-items: center;

  @media ${device.laptop} {
    width: 900px;
  }
`;
