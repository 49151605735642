import React from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import { Link } from "react-router-dom";

import { device } from "../../device";
import { sharedStyles } from "../../components/Themes";

import { Button } from "../../components/Button";
import { PageTitle } from "../../components/PageTitle";
import { Avatar } from "../../components/Avatar";
import { Text } from "../../components/Text";
import { SocmedButton } from "../../components/SocmedButton";

import { PortfolioContainer, ButtonsContainer, PageBorder } from "../";
import { ContentTitle } from "../../components/ContentTitle";

export const ProfilePage = (props) => {
  return (
    <PageBorder>
      <Container>
        <PageTitle>profile</PageTitle>

        <ProfileSection>
          <ProfileContainer>
            <Avatar />
            <Textgroup>
              <Text variant="titleText">Jiro Ricaro</Text>
              <Text variant="subTitleText">
                I'm a{" "}
                <b>
                  <Typewriter
                    options={{
                      strings: [
                        "Software Engineer",
                        "UI/UX Enthusiast",
                        "Gaming Expert",
                        "Comics Geek",
                        "Freediver",
                        "Rider"
                      ],
                      autoStart: true,
                      loop: true,
                      deleteSpeed: 20,
                      delay: 40,
                    }}
                  />
                </b>
              </Text>
            </Textgroup>
            <SocmedContainer>
              <Link
                to={{ pathname: "https://www.facebook.com/jiro.ricaro/" }}
                target="_blank"
              >
                <SocmedButton
                  icon="facebook"
                  color="facebook"
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
              </Link>
              <Link
                to={{ pathname: "https://twitter.com/JrRicaro" }}
                target="_blank"
              >
                <SocmedButton
                  icon="twitter"
                  color="twitter"
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
              </Link>
              <Link
                to={{ pathname: "https://github.com/jiroRi" }}
                target="_blank"
              >
                <SocmedButton
                  icon="github"
                  color="github"
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
              </Link>
              <Link
                to={{
                  pathname: "https://www.linkedin.com/in/jrRicaro/",
                }}
                target="_blank"
              >
                <SocmedButton
                  icon="linkedin"
                  color="linkedin"
                  style={{ width: "40px", height: "40px", borderRadius: "50%" }}
                />
              </Link>
            </SocmedContainer>
          </ProfileContainer>

          <ButtonsContainer>
            <Link to="/contact">
              <Button onClick={() => { }}>Hire Me</Button>
            </Link>
            <Link to="/about">
              <Button onClick={() => { }}>Learn More</Button>
            </Link>
          </ButtonsContainer>
        </ProfileSection>

        <ContentTitle text="Portfolio Highlights" icon="portfolio" />

        <PortfolioContainer
          imageClicked={props.imageClicked}
          portfolioHighlights={props.portfolioHighlights}
        />
      </Container>
    </PageBorder>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const ProfileSection = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 400px;
  margin: 40px auto 32px auto;

  & > * {
    margin-bottom: 32px;
  }

  & > :last-child {
    margin-bottom: 0px;
  }

  @media ${device.tablet} {
    flex-flow: row;
    align-items: flex-start;
    justify-content: space-between;
    max-width: none;
    width: 100%;

    & > :first-child {
      margin-bottom: 0px;
    }
  }

  @media ${device.laptop} {
    margin: 24px 0 80px 0;
    justify-content: space-between;
  }

  @media ${device.desktopM} {
    justify-content: flex-start;
  }
`;

const ProfileContainer = styled.div`
  position: relative;

  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 8px;
  padding: 96px 16px 16px 16px;
  height: auto;
  width: 100%;
  max-width: 400px;

  @media ${device.tablet} {
    margin-left: 24px;

    max-width: 320px;
  }

  @media ${device.laptop} {
    justify-content: center;
    align-items: center;
    height: auto;
    max-width: 400px;
    padding: 24px 16px 24px 112px;
    margin-left: 48px;

    & > :first-child {
      margin-bottom: 0;
      margin-right: 32px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
`;

const Textgroup = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;

  & > * {
    line-height: 1.25;
    margin-bottom: 4px;
  }

  & > *:last-child {
    margin-top: 4px;
    margin-bottom: 0px;
    line-height: 1.5;
  }

  & > *:first-child {
    font-size: ${sharedStyles.fontSizes.xxl};
  }

  & div {
    display: inline;
  }

  @media ${device.laptop} {
    text-align: left;
    margin-left: 8px;
    margin-top: 8px;
  }
`;

const SocmedContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;

  & > * {
    margin: 6px;
  }

  @media ${device.laptop} {
    justify-content: flex-start;
    margin-left: -8px;
  }
`;
