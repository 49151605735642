import React from "react";
import styled from "styled-components";

import { CardTechUsedIcons } from "./CardTechUsedIcons";
import { Text } from "../../../Text";

import {
  HtmlLogo,
  CssLogo,
  JavascriptLogo,
  SassLogo,
  FigmaLogo,
  NpmLogo,
  PhotoshopLogo,
  ReactLogo,
  VscodeLogo,
} from "../../../../assets/logos";

export const CardTechUsed = (props) => {
  return (
    <Tech>
      <Text variant="subTitleText" size="sm">
        Tech Used:
      </Text>
      <Container>
        {props.htmlLogo && (
          <CardTechUsedIcons>
            <HtmlLogo />
          </CardTechUsedIcons>
        )}
        {props.cssLogo && (
          <CardTechUsedIcons>
            <CssLogo />
          </CardTechUsedIcons>
        )}
        {props.jsLogo && (
          <CardTechUsedIcons>
            <JavascriptLogo />
          </CardTechUsedIcons>
        )}
        {props.sassLogo && (
          <CardTechUsedIcons>
            <SassLogo />
          </CardTechUsedIcons>
        )}
        {props.reactLogo && (
          <CardTechUsedIcons>
            <ReactLogo />
          </CardTechUsedIcons>
        )}
        {props.npmLogo && (
          <CardTechUsedIcons>
            <NpmLogo />
          </CardTechUsedIcons>
        )}
        {props.vsLogo && (
          <CardTechUsedIcons>
            <VscodeLogo />
          </CardTechUsedIcons>
        )}
        {props.figmaLogo && (
          <CardTechUsedIcons>
            <FigmaLogo />
          </CardTechUsedIcons>
        )}
        {props.psLogo && (
          <CardTechUsedIcons>
            <PhotoshopLogo />
          </CardTechUsedIcons>
        )}
      </Container>
    </Tech>
  );
};

const Tech = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  justify-content: space-between;

  margin: 16px 0 8px 0;

  & > :first-child {
    margin-bottom: 8px;
  }
`;

const Container = styled.div`
  display: flex;
  flex-flow: row wrap;

  width: 100%;
  border-radius: 8px;

  & > * {
    margin-left: 8px;
    margin-bottom: 8px;

    &:last-child {
      margin-right: 0;
    }
  }
`;
