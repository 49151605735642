import React from "react";
import styled from "styled-components";

import { MobileMenu } from "../Header/Menu";
import { ProfileCard, Logo } from "../../components/Navigation";

export const MobileNavigation = (props) => {
  return (
    <Container {...props}>
      <Navigation>
        <ProfileCard />
        <MobileMenu navClicked={props.navClicked} />
        <Logo />
      </Navigation>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  left: ${(props) => (props.opened ? "0" : "-100%")};
  z-index: 5000;

  transition: left 300ms 50ms ease;
`;

const Navigation = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  background-color: ${(props) => props.theme.body};
  padding: 16px;
  max-width: 440px;
  width: 75vw;
  height: 100vh;

  transition: left 400ms ease;

  & > :nth-child(3) {
    margin-top: auto;
  }
`;
