import React from "react";
import styled from "styled-components";

import { sharedStyles } from "../../Themes/";
import { device } from "../../../device";

export const Skill = (props) => {
  return <Container {...props}>{props.text}</Container>;
};

const Container = styled.div`
  padding: 4px 12px;
  color: ${(props) =>
    props.variant === "design"
      ? props.theme.skillsDesignText
      : props.variant === "code"
      ? props.theme.skillsCodeText
      : null};
  background-color: ${(props) =>
    props.variant === "design"
      ? props.theme.skillsDesignBackground
      : props.variant === "code"
      ? props.theme.skillsCodeBackground
      : null};
  box-shadow: ${(props) =>
    props.variant === "design"
      ? props.theme.skillsDesignBoxShadow
      : props.variant === "code"
      ? props.theme.skillsCodeBoxShadow
      : null};
  border-radius: 8px;
  margin: 6px 4px;
  font-weight: ${sharedStyles.fontWeight.medium};

  @media ${device.tablet} {
    font-size: ${sharedStyles.fontSizes.sm};
  }
`;
