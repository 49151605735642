import React from "react";
import styled from "styled-components";

import avatar from "../../assets/images/thumbnails/avatar-2.jpg";

export const AvatarRound = () => {
  return (
    <Container>
      <Image src={avatar} alt="Jiro Ricaro's Avatar" />
    </Container>
  );
};

const Container = styled.div`
  position: absolute;
  top: -56px;
  left: 50%;
  z-index: 15;

  padding: 16px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};

  transform: translateX(-50%);
`;

const Image = styled.img`
  width: 80px;
  height: 80px;
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 50%;
`;
