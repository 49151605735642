import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { device } from "../../device";
import { ContactCard } from "./ContactCard/ContactCard";
import { ButtonsPanel } from "./ButtonsPanel/ButtonsPanel";
import { PageTitle } from "../../components/PageTitle";
import { SocmedButton } from "../../components/SocmedButton";
import { PageBorder } from "../";

export const ContactPage = () => {
  return (
    <PageBorder>
      <Container>
        <PageTitle>Contact</PageTitle>
        <Row>
          <ContactCard />
          <ButtonsPanel />
        </Row>
        <SocmedContainer>
          <Link
            to={{ pathname: "https://www.facebook.com/jiro.ricaro/" }}
            target="_blank"
          >
            <SocmedButton icon="facebook" color="facebook" />
          </Link>
          <Link
            to={{ pathname: "https://dribbble.com/jiroRi" }}
            target="_blank"
          >
            <SocmedButton icon="dribbble" color="dribbble" />
          </Link>
          <Link to={{ pathname: "https://github.com/jiroRi" }} target="_blank">
            <SocmedButton icon="github" color="github" />
          </Link>

          <Link
            to={{ pathname: "https://www.messenger.com/t/100065653414257" }}
            target="_blank"
          >
            <SocmedButton icon="messenger" color="messenger" />
          </Link>

          <Link
            to={{ pathname: "https://join.skype.com/invite/U9sTNbzCx0vV" }}
            target="_blank"
          >
            <SocmedButton icon="skype" color="skype" />
          </Link>
          <Link to={{ pathname: "https://codepen.io/JiroRi" }} target="_blank">
            <SocmedButton icon="codepen" color="codepen" />
          </Link>

          <Link
            to={{ pathname: "https://twitter.com/JrRicaro" }}
            target="_blank"
          >
            <SocmedButton icon="twitter" color="twitter" />
          </Link>

          <Link
            to={{
              pathname: "https://www.linkedin.com/in/jrRicaro",
            }}
            target="_blank"
          >
            <SocmedButton icon="linkedin" color="linkedin" />
          </Link>
        </SocmedContainer>
      </Container>
    </PageBorder>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;

  width: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
`;

const SocmedContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  padding: 16px;
  width: 100%;
  max-width: 592px;
  min-height: 96px;
  margin-top: 40px;

  & > * {
    margin: 8px;
  }

  @media ${device.tablet} {
    margin-top: 40px;
  }

  @media ${device.laptop} {
    margin-top: 0;
    margin-bottom: 40px;
  }
`;
