import React from "react";
import styled from "styled-components";

import { IconContext } from "react-icons";

import {
  FaDribbble,
  FaFacebookSquare,
  FaFacebookMessenger,
  FaSkype,
  FaTwitter,
  FaCodepen,
  FaLinkedin,
  FaGithub,
} from "react-icons/fa";

export const SocmedButton = (props) => {
  return (
    <IconContext.Provider value={{ size: "24px" }}>
      <Container {...props}>
        {props.icon === "dribbble" && <FaDribbble />}
        {props.icon === "facebook" && <FaFacebookSquare />}
        {props.icon === "messenger" && <FaFacebookMessenger />}
        {props.icon === "skype" && <FaSkype />}
        {props.icon === "twitter" && <FaTwitter />}
        {props.icon === "codepen" && <FaCodepen />}
        {props.icon === "linkedin" && <FaLinkedin />}
        {props.icon === "github" && <FaGithub />}
      </Container>
    </IconContext.Provider>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 50%;
  max-width: 48px;
  max-height: 48px;

  cursor: pointer;

  & > * {
    fill: ${(props) =>
      props.color === "facebook"
        ? props.theme.facebook
        : props.color === "messenger"
        ? props.theme.messenger
        : props.color === "dribbble"
        ? props.theme.dribbble
        : props.color === "skype"
        ? props.theme.skype
        : props.color === "twitter"
        ? props.theme.twitter
        : props.color === "codepen"
        ? props.theme.default
        : props.color === "linkedin"
        ? props.theme.linkedin
        : props.color === "github"
        ? props.theme.default
        : props.theme.titleText};

    transition: transform 200ms linear;
  }

  &:active {
    box-shadow: ${(props) => props.theme.boxShadowEngraved};

    & > * {
      position: relative;
      top: 2px;
      fill: ${(props) => props.theme.subTitleText};
    }
  }

  &:hover {
    & > * {
      transform: rotate(15deg);
    }
  }
`;
