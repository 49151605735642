import React from "react";
import styled from "styled-components";

import { IconContainer } from "../../containers/";
import { Text } from "../Text";

export const ContentTitle = (props) => {
  return (
    <Container {...props}>
      <IconContainer icon={props.icon} />
      <Text variant="subTitleText" size={props.size}>
        {props.text}
      </Text>
    </Container>
  );
};

const Container = styled.div`
  display: inline-flex;
  flex-flow: row;
  align-items: center;
  text-align: left;

  margin-bottom: 8px;

  & > :first-child {
    margin-right: 4px;
  }
`;
