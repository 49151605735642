import React from "react";
import styled from "styled-components";

export const CardThumbnail = (props) => {
  return (
    <Container {...props}>
      <Image {...props} onClick={props.imageClicked} />
    </Container>
  );
};

const Container = styled.div`
  padding: 16px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border-radius: 8px;
  width: 100%;

  cursor: pointer;

  animation: ${(props) =>
    props.opened === true
      ? "cardOpened 350ms ease-in-out forwards"
      : props.opened === false
      ? "cardClosed 350ms ease-in-out forwards"
      : null};

  @keyframes cardOpened {
    0% {
      transform: scale(1.05);
      opacity: 1;
    }

    100% {
      transform: scale(0);
      opacity: 0;
    }
  }

  @keyframes cardClosed {
    from {
      transform: scale(0);
      opacity: 0;
    }
    to {
      transform: scale(1);
      opacity: 1;
    }
  }
`;

const Image = styled.div`
  position: relative;
  z-index: 1;

  width: 100%;
  height: 136px;
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  background-image: url(${(props) => props.image});
  background-size: 100% 100%;
  border-radius: 8px;
  opacity: 0.9;
`;
