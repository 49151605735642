import React from "react";
import styled from "styled-components";

import { device } from "../device";

export const ButtonsContainer = (props) => {
  return <Container>{props.children}</Container>;
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 24px 32px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};

  border-radius: 8px;
  width: 100%;

  & > * {
    margin-bottom: 16px;
    width: 100%;

    &:last-child {
      margin-bottom: 0px;
    }
  }

  & button {
    width: 100%;
  }

  @media ${device.tablet} {
    max-width: 240px;
  }

  @media ${device.laptop} {
    margin: 0 auto;
  }

  @media ${device.desktopM} {
    margin: 0 0 0 80px;
  }
`;
