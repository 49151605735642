/* thumbnails */
import urlShortener from "./assets/images/thumbnails/url-shortener.jpg";
import socialMediaDashboard from "./assets/images/thumbnails/social-media-dashboard.jpg";
import dailyUi from "./assets/images/thumbnails/daily-ui.jpg";
import chatApp from "./assets/images/thumbnails/chat-app-css.jpg";
import foodWebsite from "./assets/images/thumbnails/food-website.jpg";
import insureLandingPage from "./assets/images/thumbnails/insure-landing-page.jpg";
import oldwebsite from "./assets/images/thumbnails/oldwebsite.jpg";
import portfolioLandingPage from "./assets/images/thumbnails/portfolio-landing-page.jpg";
import testimonialsSlider from "./assets/images/thumbnails/testimonials-slider.jpg";
import burgerbuilder from "./assets/images/thumbnails/burgerbuilder.jpg";
import realEstateLandingPage from "./assets/images/thumbnails/real-estate-landing-page.jpg";
import neumorphismDesign from "./assets/images/thumbnails/neumorphism.jpg";
import ipTracker from "./assets/images/thumbnails/ip-tracker.jpg";
import slerpThumbnail from "./assets/images/thumbnails/slerp.jpg";

/* full size */
import urlShortenerFull from "./assets/images/fullsize/url-shortener-FULL.jpg";
import socialMediaDashboardFull from "./assets/images/fullsize/social-media-dashboard-FULL.jpg";
import dailyUiFull from "./assets/images/fullsize/daily-ui-FULL.jpg";
import chatAppFull from "./assets/images/fullsize/chat-app-css-FULL.jpg";
import foodWebsiteFull from "./assets/images/fullsize/food-website-FULL.jpg";
import oldwebsiteFull from "./assets/images/fullsize/oldwebsite-FULL.jpg";
import burgerBuilderFull from "./assets/images/fullsize/burgerbuilder-FULL.jpg";
import insureLandingPageFull from "./assets/images/fullsize/insure-landing-page-FULL.jpg";
import portfolioLandingPageFull from "./assets/images/fullsize/portfolio-landing-page-FULL.jpg";
import realEstateLandingPageFull from "./assets/images/fullsize/real-estate-landing-page-FULL.jpg";
import neumorphismDesignFull from "./assets/images/fullsize/neumorphism-FULL.jpg";
import ipTrackerFull from "./assets/images/fullsize/ip-tracker-FULL.jpg";
import slerpFull from "./assets/images/fullsize/slerp.jpg";
import restCountriesFull from "./assets/images/fullsize/rest-countries.jpg";

/* import placeholder from "./assets/images/fullsize/placeholder.png"; */

/* import googleMapsFull from './assets/images/thumbnails/google-maps-api.jpg'; */

/* import insureLandingPageFull from "./assets/images/fullsize/insure-landing-page-FULL.jpg"; */

/* import portfolioLandingPageFull from "./assets/images/fullsize/portfolio-landing-page-FULL.jpg"; */

import testimonialsSliderFull from "./assets/images/fullsize/testimonials-slider-FULL.jpg";

export const PortfolioData = [
  {
    id: "1-slerp",
    category: "code",
    image: slerpThumbnail,
    fullImage: slerpFull,
    title: "Slerp",
    details:
      "In-charge of adding new and modifying existing features across our web apps via both Frontend and Backend.",
    highlights: true,

    url: "https://slerp.com",

    /* tech used logos */
    htmlLogo: false,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    figmaLogo: false,
    npmLogo: true,
    psLogo: false,
    vsLogo: true,
  },
  {
    id: "2-rest-countries",
    category: "code",
    image: restCountriesFull,
    fullImage: restCountriesFull,
    title: "Rest Countries",
    details:
      "A web app for searching countries and their basic information using REST Countries API.",
    highlights: true,

    url: "https://rest-countries-api-jirori.vercel.app/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    figmaLogo: false,
    npmLogo: true,
    psLogo: false,
    vsLogo: true,
  },
  {
    id: "url-shortener",
    category: "code",
    image: urlShortener,
    fullImage: urlShortenerFull,
    title: "URL Shortener API",
    details:
      "A pixel-perfect website solution to a challenge from Frontend Mentor that requires the utilization of the URL Shortener API.",
    highlights: false,

    url: "https://jovial-bassi-d3cab2.netlify.app/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    figmaLogo: false,
    npmLogo: false,
    psLogo: false,
    vsLogo: true,
  },
  {
    id: "socialMediaDashboard",
    category: "code",
    image: socialMediaDashboard,
    fullImage: socialMediaDashboardFull,
    title: "Social Media Dashboard",
    details:
      "A pixel-perfect website solution to a challenge from Frontend Mentor that requires the functionality of switching between themes(Dark/Light mode).",
    highlights: false,

    url: "https://confident-ride-8aa3a0.netlify.app/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    figmaLogo: false,
    npmLogo: false,
    psLogo: false,
    vsLogo: true,
  },
  {
    id: "4-dailyUi",
    category: "design",
    image: dailyUi,
    fullImage: dailyUiFull,
    title: "Daily UI",
    details:
      "A curation of my submission(on-hold/on-going progress) for the one hundred days of #DailyUI challenge.",
    highlights: true,

    url: "https://www.figma.com/file/qUssinVdzQ6Pns6tNOmd9k/?node-id=528%3A5183",

    /* tech used logos */
    htmlLogo: false,
    cssLogo: false,
    jsLogo: false,
    sassLogo: false,
    figmaLogo: true,
    npmLogo: false,
    psLogo: true,
    vsLogo: false,
  },
  {
    id: "chatApp",
    category: "code",
    image: chatApp,
    fullImage: chatAppFull,
    title: "Chat CSS Animation",
    details:
      "A pixel-perfect website solution to a challenge from Frontend Mentor that tests a developer's knowledge of CSS Animations.",
    highlights: false,

    url: "https://naughty-blackwell-54b162.netlify.app/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    figmaLogo: false,
    npmLogo: false,
    psLogo: false,
    vsLogo: true,
  },
  {
    id: "foodWebsite",
    category: "design",
    image: foodWebsite,
    fullImage: foodWebsiteFull,
    title: "Food Business Website",
    details:
      "A mockup design I made with reference to my older sister's food business during the COVID-19 pandemic.",
    highlights: false,

    url: "https://www.figma.com/file/qUssinVdzQ6Pns6tNOmd9k/Design-Portfolio?node-id=528%3A667",

    /* tech used logos */
    htmlLogo: false,
    cssLogo: false,
    jsLogo: false,
    sassLogo: false,
    figmaLogo: true,
    npmLogo: false,
    psLogo: true,
    vsLogo: false,
  },
  {
    id: "insureLandingPage",
    category: "code",
    image: insureLandingPage,
    fullImage: insureLandingPageFull,
    title: "Insure Landing Page",
    details:
      "A pixel-perfect website solution to a challenge from Frontend Mentor. This challenge had tested my skills when it comes to coding a landing page on the dot from the provided design. This challenge have also made me improve my understanding of Flexbox and Responsive Design.",
    highlights: false,

    url: "https://cranky-noether-dc072e.netlify.app/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    figmaLogo: false,
    npmLogo: false,
    psLogo: false,
    vsLogo: true,
  },
  {
    id: "portfolioLandingPage",
    category: "design",
    image: portfolioLandingPage,
    fullImage: portfolioLandingPageFull,
    title: "Portfolio Landing Page",
    details:
      "An old landing page design I made for my portfolio website. This also includes the earlier design versions of my portfolio website.",
    highlights: false,

    url: "https://www.figma.com/file/qUssinVdzQ6Pns6tNOmd9k/?node-id=528%3A1231",

    /* tech used logos */
    htmlLogo: false,
    cssLogo: false,
    jsLogo: false,
    sassLogo: false,
    figmaLogo: true,
    npmLogo: false,
    psLogo: true,
    vsLogo: false,
  },
  {
    id: "testimonialsSlider",
    category: "code",
    image: testimonialsSlider,
    fullImage: testimonialsSliderFull,
    title: "Testimonials Slider",
    details:
      "A pixel-perfect website solution to a challenge from Frontend Mentor. This challenge mainly requires the image slider functionality.",
    highlights: false,

    url: "https://testimonials-slider-one.vercel.app/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    figmaLogo: false,
    npmLogo: false,
    psLogo: false,
    vsLogo: true,
  },
  {
    id: "oldWebsite",
    category: "code",
    image: oldwebsite,
    fullImage: oldwebsiteFull,
    title: "Old Portfolio Website",
    details:
      "This is the 2nd version of my portfolio website. I have designed and prototyped this using Figma and built from scratch using HTML, SASS/SCSS, vanilla Javascript.",
    highlights: false,

    url: "http://jiroricaro-deprecated.netlify.com/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    figmaLogo: true,
    npmLogo: false,
    psLogo: true,
    vsLogo: true,
  },
  {
    id: "6-burger-builder",
    category: "code",
    image: burgerbuilder,
    fullImage: burgerBuilderFull,
    title: "Burger Builder App",
    details:
      "A test project for learning ReactJS derived from The Complete React Course on Udemy.",
    highlights: true,

    url: "https://burger-builder-fc418.web.app/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    reactLogo: true,
    figmaLogo: false,
    npmLogo: true,
    psLogo: false,
    vsLogo: true,
  },
  {
    id: "realEstateLandingPage",
    category: "design",
    image: realEstateLandingPage,
    fullImage: realEstateLandingPageFull,
    title: "Real Estate Landing Page",
    details:
      "An attempt to make a design for a real estate website's landing page.",
    highlights: false,

    url: "https://www.figma.com/file/qUssinVdzQ6Pns6tNOmd9k/?node-id=528%3A1901",

    /* tech used logos */
    htmlLogo: false,
    cssLogo: false,
    jsLogo: false,
    sassLogo: false,
    reactLogo: false,
    figmaLogo: true,
    npmLogo: false,
    psLogo: true,
    vsLogo: false,
  },
  {
    id: "5-neumorphism-design",
    category: "design",
    image: neumorphismDesign,
    fullImage: neumorphismDesignFull,
    title: "Neumorphism Design",
    details:
      "An earlier version of my redesign for my portfolio website. Pages and their mobile versions are all included.",
    highlights: true,

    url: "https://www.figma.com/file/qUssinVdzQ6Pns6tNOmd9k/?node-id=528%3A619",

    /* tech used logos */
    htmlLogo: false,
    cssLogo: false,
    jsLogo: false,
    sassLogo: false,
    reactLogo: false,
    figmaLogo: true,
    npmLogo: false,
    psLogo: true,
    vsLogo: false,
  },
  {
    id: "3-ip-tracker",
    category: "code",
    image: ipTracker,
    fullImage: ipTrackerFull,
    title: "IP Address Tracker App",
    details:
      "An app that enables the user to view a particular IP or Domain's information.",
    highlights: true,

    url: "https://sharp-knuth-db9a7b.netlify.app/",

    /* tech used logos */
    htmlLogo: true,
    cssLogo: true,
    jsLogo: true,
    sassLogo: true,
    reactLogo: true,
    figmaLogo: true,
    npmLogo: true,
    psLogo: false,
    vsLogo: true,
  },
];

export const CodeSkills = [
  "HTML",
  "CSS",
  "JAVASCRIPT",
  "SASS/SCSS",
  "REACTJS",
  "GRAPHQL",
  "REDUX",
  "ANT DESIGN",
  "MATERIAL UI",
  "GIT",
  "STYLED COMPONENTS",
  "FIREBASE",
  "EMOTION",
  "CMS",
  "NPM",
  "CSS ANIMATIONS",
  "REACT SPRING",
  "POSTMAN",
  "PHP",
  "STRIPE API",
  "LESS",
  "REACT-TESTING",
  "ELIXIR",
  "USERFLOW",
  "DOCKER",
  "WSL2",
];

export const DesignSkills = [
  "FIGMA",
  "WEB DESIGN",
  "UI/UX",
  "PHOTOSHOP",
  "PROTOTYPING",
  "WIREFRAME",
  "REFACTORING UI",
  "NEUMORPHISM",
];
