import React, { Suspense, lazy, useState } from "react";
import styled from "styled-components";

import Spinner from "../Spinner/Spinner";

const Image = lazy(() => import("./Image"));

export const FullImage = (props) => {
  const [loading, setLoading] = useState(true);

  return (
    <Container isOpened={props.isOpened} onClick={props.clicked}>
      <Suspense fallback={<Spinner />}>
        <Image
          src={props.displayedImage}
          onLoad={() => setLoading(false)}
          isImageDisplayed={!loading}
        />
        <Spinner />
      </Suspense>
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  top: 0;
  height: 0;
  z-index: 10000;

  display: ${(props) => (props.isOpened ? "block" : "none")};
  width: 100vw;
  height: 100vh;
`;
