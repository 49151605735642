import React from "react";
import styled from "styled-components";

export const Toast = (props) => {
  return (
    <Container onClick={props.toastClicked} {...props}>
      Copied To Clipboard!
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  bottom: ${(props) => (props.visible ? "1%" : "-100%")};
  left: 50%;
  z-index: 1000;

  padding: 16px;
  background-color: ${(props) => props.theme.toast};
  color: #fff;
  opacity: 0.75;

  font-size: 0.875rem;

  cursor: pointer;
  transform: translateX(-50%);
  transition: bottom 400ms ease-in-out;
`;
