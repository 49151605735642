import React from "react";
import styled from "styled-components";

import { device } from "../../device";

export const Hamburger = (props) => {
  return (
    <Border>
      <Container {...props}>
        <Line />
        <Line />
        <Line />
      </Container>
    </Border>
  );
};

const Border = styled.div`
  padding: 4px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};

  @media ${device.tablet} {
    display: none;
  }
`;

const Container = styled.button`
  display: flex;
  flex-flow: column;
  justify-content: space-between;

  height: 32px;
  width: 32px;
  padding: 6px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 8px;
  outline: none;
  border: none;

  &:active,
  &:focus {
    box-shadow: ${(props) => props.theme.boxShadowEngraved};
  }

  &:active > *,
  &:focus > * {
    top: 2px;
    background-color: ${(props) => props.theme.hamburgerLinesActive};
  }
`;

const Line = styled.div`
  position: relative;
  width: 100%;
  height: 4px;
  background-color: ${(props) => props.theme.hamburgerLines};
  border-radius: 8px;
`;
