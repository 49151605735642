import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { IconContext } from "react-icons";

import {
  FaFolderOpen,
  FaInfoCircle,
  FaHistory,
  FaGraduationCap,
  FaKeyboard,
  FaPaintBrush,
  FaPhone,
} from "react-icons/fa";

import { MdMail, MdMyLocation } from "react-icons/md";

export const IconContainer = (props) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container {...props}>
      <IconContext.Provider
        value={{ color: themeContext.subTitleText, size: "20px" }}
      >
        {props.icon === "portfolio" && <FaFolderOpen />}

        {props.icon === "about" && <FaInfoCircle />}

        {props.icon === "career" && <FaHistory />}

        {props.icon === "education" && <FaGraduationCap />}

        {props.icon === "code" && <FaKeyboard />}

        {props.icon === "design" && <FaPaintBrush />}

        {props.icon === "phone" && <FaPhone />}

        {props.icon === "email" && <MdMail />}

        {props.icon === "location" && <MdMyLocation />}
      </IconContext.Provider>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 2px;
`;
