import React from "react";
import styled from "styled-components";

import { Text } from "../../../Text";
import { CardCategoryIcon } from "./CardCategoryIcon/CardCategoryIcon";

export const CardTitle = (props) => {
  return (
    <Border>
      <Container {...props}>
        <CardCategoryIcon
          category={props.category}
          moveIcon={props.moveElements}
        />
        <Text variant="titleText" size="xs" {...props}>
          {props.children}
        </Text>
      </Container>
    </Border>
  );
};

const Border = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  padding: 8px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  background-color: ${(props) => props.theme.body};
  width: calc(78%);
`;

const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 12px 8px;
  border-radius: 8px;
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  background-color: ${(props) => props.theme.body};
  min-height: 40px;
  width: 100%;

  & > :last-child {
    position: relative;
    left: 0;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    margin-left: 2px;
    margin-right: 4px;
    line-height: 1.25;

    animation: ${(props) =>
      props.moveTitle
        ? "moveTitleToRight 300ms ease-in forwards"
        : "moveTitleToLeft 300ms ease-in forwards"};
  }

  @keyframes moveTitleToRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(4px);
    }
  }

  @keyframes moveTitleToLeft {
    from {
      transform: translateX(4px);
    }
    to {
      transform: translateX(0);
    }
  }
`;
