import React from "react";
import styled from "styled-components";

import { sharedStyles } from "../Themes";

export const Text = (props) => {
  return <StyledText {...props}>{props.children}</StyledText>;
};

const StyledText = styled.div`
  margin: 0;
  color: ${(props) => props.theme.subTitleText};

  font-size: ${sharedStyles.md};
  line-height: 1.5;

  ${(props) =>
    props.variant === "pageTitle" &&
    `
  color: ${props.theme.pageTitle};`}

  ${(props) =>
    props.variant === "titleText" &&
    `
  color: ${props.theme.titleText};
  font-weight: ${sharedStyles.fontWeight.semiBold};`}

  ${(props) =>
    props.variant === "subTitleText" &&
    `
  color: ${props.theme.subTitleText};
  font-weight: ${sharedStyles.fontWeight.medium};`}

  ${(props) =>
    props.variant === "bodyText" &&
    `
  color: ${props.theme.bodyText};
  font-weight: ${sharedStyles.fontWeight.regular};`}

  ${(props) =>
    props.variant === "buttonText" &&
    `
  color: ${props.theme.buttonText};`}

  ${(props) =>
    props.variant === "careerText" &&
    `
  color: ${props.theme.careerText};
  font-weight: ${sharedStyles.fontWeight.semiBold};`}

  ${(props) =>
    props.variant === "educationText" &&
    `
  color: ${props.theme.educationText};
  font-weight: ${sharedStyles.fontWeight.semiBold};`}

  ${(props) =>
    props.size === "xs" &&
    `
    font-size: ${sharedStyles.fontSizes.xs};
    `}

  ${(props) =>
    props.size === "sm" &&
    `
    font-size: ${sharedStyles.fontSizes.sm};`}

    ${(props) =>
    props.size === "md" &&
    `
    font-size: ${sharedStyles.fontSizes.md};`}

    ${(props) =>
    props.size === "lg" &&
    `
    font-size: ${sharedStyles.fontSizes.lg};`}

    ${(props) =>
    props.size === "xl" &&
    `
    font-size: ${sharedStyles.fontSizes.xl};`}

    ${(props) =>
    props.size === "xxl" && `font-size: ${sharedStyles.fontSizes.xxl};`}

    ${(props) =>
    props.size === "xxxl" &&
    `
    font-size: ${sharedStyles.fontSizes.xxxl};`}
`;
