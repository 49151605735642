import React from "react";
import styled from "styled-components";

import { Skill } from "./Skill/Skill";
import { CodeSkills, DesignSkills } from "../../PortfolioData";
import { ContentTitle } from "../ContentTitle";

import { device } from "../../device";

export const Skills = (props) => {
  let codeSkills = CodeSkills.map((skill, index) => {
    return <Skill key={index} text={skill} variant={props.variant} />;
  });

  let designSkills = DesignSkills.map((skill, index) => {
    return <Skill key={index} text={skill} variant={props.variant} />;
  });

  let contentTitleText =
    props.variant === "design"
      ? "Design Skills"
      : props.variant === "code"
      ? "Code Skills"
      : null;

  let contentTitleIcon =
    props.variant === "design"
      ? "design"
      : props.variant === "code"
      ? "code"
      : null;

  return (
    <Container>
      <ContentTitle text={contentTitleText} icon={contentTitleIcon} />
      <Border>
        <SkillsContainer>
          {props.variant === "code"
            ? codeSkills
            : props.variant === "design"
            ? designSkills
            : null}
        </SkillsContainer>
      </Border>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
`;

const Border = styled.div`
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border-radius: 8px;
  padding: 8px;
  width: 100%;

  @media ${device.tablet} {
    max-width: 280px;
  }
`;

const SkillsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  overflow-y: auto;

  @media ${device.tablet} {
    max-height: 360px;
  }

  @media ${device.laptop} {
    max-height: 264px;
  }
`;
