import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { device } from "../../../device";

import { AvatarRound, Text, ContentTitle } from "../../../components/";
import { ContentBorder } from "../../";
import { Toast } from "../../../components/Toast";

export const ContactCard = () => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyText = () => {
    setIsCopied(true);
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsCopied(false);
    }, 3000);

    return () => {
      clearTimeout(timer);
    };
  }, [isCopied]);

  return (
    <Container>
      <AvatarRound />
      <Text variant="titleText" size="xl">
        Jiro Ricaro
      </Text>
      <Text variant="subTitleText" size="md">
        <b>
          <Typewriter
            className="typewriter"
            options={{
              strings: [
                "Software Engineer",
                "UI/UX Enthusiast",
                "Gaming Expert",
                "Comics Geek",
                "Freediver",
                "Rider"
              ],
              autoStart: true,
              loop: true,
              deleteSpeed: 20,
              delay: 40,
            }}
          />
        </b>
      </Text>
      <ContentBorder>
        <Toast
          visible={isCopied}
          toastClicked={() => {
            setIsCopied(false);
          }}
        />
        <Content>
          <CopyToClipboard text="+639216918001" onCopy={onCopyText}>
            <ContentTitle icon="phone" text="+63 921 691 8001" size="sm" />
          </CopyToClipboard>

          <CopyToClipboard
            text="developer@jiroricaro.com"
            onCopy={onCopyText}
          >
            <ContentTitle
              icon="email"
              text="developer@jiroricaro.com"
              size="sm"
            />
          </CopyToClipboard>
          <ContentTitle
            icon="location"
            text="Quezon City, Philippines"
            size="sm"
          />
        </Content>
      </ContentBorder>
    </Container>
  );
};

const Container = styled.div`
  position: relative;

  display: flex;
  flex-flow: column;
  align-items: center;
  width: 100%;
  max-width: 320px;
  padding: 72px 24px 24px 24px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border-radius: 8px;
  margin: 56px 0 0 0;

  text-align: center;

  & > :last-child {
    display: flex;
    flex-flow: column;
    align-items: center;
    width: 100%;
    padding: 24px 8px;
    margin-top: 24px;
  }

  & > :nth-child(3) {
    display: flex;
    justify-content: center;
    width: 100%;
  }

  @media ${device.tablet} {
    margin: 56px 32px 40px 0;
  }
`;

const Content = styled.div`
  display: flex;
  flex-flow: column;
  align-items: flex-start;

  & > * {
    margin-bottom: 16px;
    cursor: pointer;
  }

  & > :last-child {
    margin-bottom: 0;
    cursor: default;
  }
`;
