import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { Button } from "../../Button";
import { Text } from "../../Text";
import { CardTechUsed } from "./CardTechUsed/CardTechUsed";

export const CardContent = (props) => {
  return (
    <Container>
      <Content>
        <Text variant="bodyText" size="sm">
          {props.details}
        </Text>
        <CardTechUsed
          htmlLogo={props.htmlLogo}
          cssLogo={props.cssLogo}
          jsLogo={props.jsLogo}
          reactLogo={props.reactLogo}
          sassLogo={props.sassLogo}
          figmaLogo={props.figmaLogo}
          psLogo={props.psLogo}
          npmLogo={props.npmLogo}
          vsLogo={props.vsLogo}
        />
      </Content>

      <Link to={{ pathname: props.url }} target="_blank">
        <Button>view page</Button>
      </Link>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-flow: column;
  justify-content: space-between;
  padding: 84px 12px 12px 16px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border-radius: 8px;

  width: 100%;
  height: 100%;

  & button {
    margin: 8px 0 0 0;
    padding: 12px 16px;
    width: 100%;
  }
`;

const Content = styled.div`
  position: relative;
  z-index: 1;
  overflow-y: auto;
  max-height: 100%;
  padding-right: 16px;
  margin-bottom: 16px;
`;
