import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";

import { FaInfoCircle, FaWindowClose } from "react-icons/fa";
import { IconContext } from "react-icons";

export const CardButton = (props) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Container {...props} onClick={props.buttonclicked}>
      {props.opened ? (
        <IconContext.Provider
          value={{
            color: themeContext.closeButton,
            size: "24px",
          }}
        >
          <FaWindowClose />
        </IconContext.Provider>
      ) : (
        <IconContext.Provider
          value={{
            color: themeContext.buttonIcon,
            size: "24px",
          }}
        >
          <FaInfoCircle />
        </IconContext.Provider>
      )}
    </Container>
  );
};

const Container = styled.button`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  padding: 8px;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  margin-left: 12px;

  cursor: pointer;

  &:active {
    box-shadow: ${(props) => props.theme.boxShadowEngraved};

    & > * {
      position: relative;
      top: 2px;
      opacity: 0.5;
    }
  }

  @keyframes onClick = {
    0% {
      opacity: 1;
    }

    50% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;
