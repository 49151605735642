import React from "react";
import styled from "styled-components";

export const NpmLogo = () => {
  return (
    <Icon width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 0h16v16H0V0z"
        fill="#D40001"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.145 5.285H8.137V13.5h-5.47V2h10.666v11.5h-2.188V5.284z"
        fill="#fff"
      />
    </Icon>
  );
};

const Icon = styled.svg``;
