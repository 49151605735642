import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { useSpring, animated } from "react-spring";

import { FaSun, FaMoon } from "react-icons/fa";
import { IconContext } from "react-icons";

export const ThemeSwitcher = (props) => {
  const moveSwitch = useSpring({
    left: props.isToggled ? "4px" : "20px",
    config: { duration: 150 },
  });

  const themeContext = useContext(ThemeContext);

  return (
    <Container {...props}>
      <Ellipse style={moveSwitch}>
        {props.isToggled ? (
          <IconContext.Provider
            value={{ color: themeContext.switcherLogo, size: "20px" }}
          >
            <FaMoon />
          </IconContext.Provider>
        ) : (
          <IconContext.Provider
            value={{ color: themeContext.switcherLogo, size: "20px" }}
          >
            <FaSun />
          </IconContext.Provider>
        )}
      </Ellipse>
    </Container>
  );
};

const Container = styled.button`
  position: relative;

  width: 56px;
  height: 40px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border: none;
  border-radius: 100px;
  outline: none;

  cursor: pointer;
`;

const Ellipse = styled(animated.div)`
  position: absolute;
  top: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
  border-radius: 50%;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};

  transform: translateY(-50%);
  transition: left 200ms ease;
`;

/* left: ${(props) => (props.isToggled ? `4px` : "calc(100% - 36px)")}; */
