import React from "react";
import styled from "styled-components";

import { CardButton, CardThumbnail, CardTitle } from "../../Card";

export const CardCover = React.memo((props) => {
  return (
    <Container {...props}>
      <Engraved />
      <CardThumbnail
        image={props.image}
        imageClicked={props.imageClicked}
        opened={props.opened}
      />
      <TitleContainer>
        <CardTitle
          category={props.category}
          moveElements={props.opened}
          moveTitle={props.opened}
        >
          {props.title}
        </CardTitle>
        <CardButton buttonclicked={props.buttonclicked} opened={props.opened} />
      </TitleContainer>
    </Container>
  );
});

const Container = styled.div`
  position: absolute;
  left: 0px;
  top: 0px;
  z-index: 150;

  display: flex;
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  padding: 12px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  width: 100%;
  height: 100%;

  animation: ${(props) =>
    props.opened === true
      ? "openAnimation 350ms ease-in-out forwards"
      : props.opened === false
      ? "closeAnimation 350ms ease-in-out forwards"
      : null};

  & > div {
    margin-bottom: 8px;
  }

  & > div:last-child {
    margin-bottom: 0;
  }

  @keyframes openAnimation {
    from {
      transform: translateY(0);
    }

    to {
      transform: translateY(-208px);
    }
  }

  @keyframes closeAnimation {
    from {
      transform: translateY(-208px);
    }
    to {
      transform: translateY(0);
    }
  }
`;

const TitleContainer = styled.div`
  position: absolute;
  bottom: 8px;
  left: 6px;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 90%;
  margin-top: auto;
  margin-left: 8px;

  @keyframes expandTitle {
    0% {
      position: absolute;
      bottom: 8px;
      left: 8px;
    }
  }
`;

const Engraved = styled.div`
  margin-left: auto;
  margin-right: auto;
  width: 40px;
  height: 8px;
  background-color ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border-radius: 8px;
`;
