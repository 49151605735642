import textureLight from "../../assets/images/textureLight.png";
import textureDark from "../../assets/images/textureDark.png";

export const sharedStyles = {
  colors: {
    gray50: "#F1F3F3",
    gray100: "#DBE0E1",
    gray200: "#C5CDCE",
    gray300: "#AFBABB",
    gray400: "#99A6A8",
    gray500: "#839395",
    gray600: "#6E7F81",
    gray700: "#5B6A6B",
    gray800: "#495455",
    gray900: "#363E3F",

    light50: "#A2ABC3",
    light100: "#929DB9",
    light200: "#838FAF",
    light300: "#7481A5",
    light400: "#64739B",
    light500: "#5A688B",
    light600: "#505D7C",
    light700: "#46516C",
    light800: "#3C455D",
    light900: "#323A4D",

    cyan50: "#F1F3F4",
    cyan100: "#D8E2E4",
    cyan200: "#BBD4D8",
    cyan300: "#9BC9CF",
    cyan400: "#78BEC9",
    cyan500: "#52B7C7",
    cyan600: "#31ACBF",
    cyan700: "#2194A6",
    cyan800: "#147B8A",
    cyan900: "#0A5E6B",

    indigo50: "#F1F1F3",
    indigo100: "#D9DAE3",
    indigo200: "#BDBFD6",
    indigo300: "#9DA2CD",
    indigo400: "#7B82C6",
    indigo500: "#5560C3",
    indigo600: "#3542BB",
    indigo700: "#2431A3",
    indigo800: "#162288",
    indigo900: "#2E314D",

    blue50: "#F1F2F3",
    blue100: "#D9DFE3",
    blue200: "#BDCCD6",
    blue300: "#9DB9CD",
    blue400: "#7BA7C6",
    blue500: "#5595C3",
    blue600: "#3583BB",
    blue700: "#246EA3",
    blue800: "#165988",
    blue900: "#0C426A",

    yellow: "#e6b319",
  },

  lightBody: `#dadee8`,
  darkBody: `#242833`,

  boxShadows: {
    lightEmbossed: `2.5px 2.5px 5px #bac1d4,
    -2.5px -2.5px 5px #e4e8f2`,
    lightEngraved: `inset 2.5px 2.5px 5px #bac1d4,
    inset -2.5px -2.5px 5px #e4e8f2`,
    lightBoxShadow: `-2px 2px 4px rgba(186, 193, 212, 0.2), 2px -2px 4px rgba(186, 193, 212, 0.2), -2px -2px 4px rgba(228, 232, 242, 0.9), 2px 2px 5px rgba(123, 130, 148, 0.9), inset 1px 1px 2px rgba(201, 212, 242, 0.3), inset -1px -1px 2px rgba(123, 130, 148, 0.5)`,
    darkEmbossed: `2.5px 2.5px 5px #1c2028,
    -2.5px -2.5px 5px #2c303e`,
    darkEngraved: `inset 2.5px 2.5px 5px #1b1e27,
            inset -2.5px -2.5px 5px #2d323f`,
    darkBoxShadow: `5px 5px 18px #1c2028,
    -5px -5px 18px #2c303e`,
  },

  font: {
    sans: `'Rubik', sans-serif`,
  },

  fontSizes: {
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.25rem",
    xl: "1.5rem",
    xxl: "2rem",
    xxxl: "2.5rem",
  },

  fontWeight: {
    light: 300,
    regular: 400,
    medium: 500,
    semiBold: 600,
    bold: 700,
    black: 900,
  },
};

export const lightTheme = {
  body: sharedStyles.lightBody,
  hamburger: sharedStyles.colors.light700,
  hamburgerLines: sharedStyles.colors.light700,
  hamburgerLinesActive: sharedStyles.colors.light300,
  modal: "#222",
  scrollbarTrack: sharedStyles.colors.light50,
  scrollbarThumb: sharedStyles.colors.light300,
  scrollbarThumbHover: sharedStyles.colors.light600,
  toast: "#000",
  texture: textureLight,

  /* texts */
  pageTitle: sharedStyles.colors.light600,
  titleText: sharedStyles.colors.light800,
  subTitleText: sharedStyles.colors.light500,
  bodyText: sharedStyles.colors.light900,
  buttonText: sharedStyles.colors.light700,
  buttonTextActive: sharedStyles.colors.light400,
  careerText: sharedStyles.colors.blue500,
  educationText: sharedStyles.colors.indigo500,

  buttonIcon: sharedStyles.colors.cyan800,
  closeButton: "#e6b319",

  /* theme switcher button */
  switcherLogo: sharedStyles.colors.yellow,

  /* skills */
  skillsCodeBackground: sharedStyles.colors.blue400,
  skillsCodeText: sharedStyles.colors.gray50,
  skillsCodeBoxShadow: `1px 1px 2px rgba(195, 229, 254, 0.3), -1px -1px 2px rgba(119, 141, 156, 0.5), inset -1px 1px 2px rgba(119, 141, 156, 0.2), inset 1px -1px 2px rgba(119, 141, 156, 0.2), inset -1px -1px 2px rgba(195, 229, 254, 0.9), inset 1px 1px 3px rgba(119, 141, 156, 0.9)`,
  skillsDesignBackground: sharedStyles.colors.indigo300,
  skillsDesignText: sharedStyles.colors.gray50,
  skillsDesignBoxShadow: `1px 1px 2px rgba(195, 201, 254, 0.3), -1px -1px 2px rgba(119, 123, 156, 0.5), inset -1px 1px 2px rgba(119, 123, 156, 0.2), inset 1px -1px 2px rgba(119, 123, 156, 0.2), inset -1px -1px 2px rgba(195, 201, 254, 0.9), inset 1px 1px 3px rgba(119, 123, 156, 0.9)`,

  /* portfolio */
  portfolioCode: sharedStyles.colors.blue600,
  portfolioDesign: sharedStyles.colors.indigo600,

  /* socmed logos */
  default: sharedStyles.colors.gray900,
  facebook: "#395185",
  twitter: sharedStyles.colors.light900,
  linkedin: "#0A66C2",
  messenger: sharedStyles.colors.light900,
  skype: sharedStyles.colors.light900,
  dribbble: "#ea4c89",

  /* box shadows */
  boxShadowEmbossed: sharedStyles.boxShadows.lightEmbossed,
  boxShadowEngraved: sharedStyles.boxShadows.lightEngraved,
  boxShadowBody: sharedStyles.boxShadows.lightBoxShadow,
};

export const darkTheme = {
  body: "#242833",
  hamburger: sharedStyles.colors.gray50,
  hamburgerLines: sharedStyles.colors.gray500,
  hamburgerLinesActive: sharedStyles.colors.gray700,
  modal: "#222222",
  scrollbarTrack: sharedStyles.colors.light900,
  scrollbarThumb: sharedStyles.colors.light400,
  scrollbarThumbHover: sharedStyles.colors.light300,
  toast: "#111",
  texture: textureDark,

  /* texts */
  pageTitle: sharedStyles.colors.gray400,
  titleText: sharedStyles.colors.gray100,
  subTitleText: sharedStyles.colors.gray500,
  bodyText: sharedStyles.colors.gray300,
  buttonText: sharedStyles.colors.gray100,
  buttonTextActive: sharedStyles.colors.cyan800,
  careerText: sharedStyles.colors.blue400,
  educationText: sharedStyles.colors.indigo400,

  buttonIcon: sharedStyles.colors.cyan700,
  closeButton: "#ffcb5c",

  /* theme switcher button */
  switcherLogo: sharedStyles.colors.blue500,

  /* skills */
  skillsCodeBackground: sharedStyles.colors.blue900,
  skillsCodeText: sharedStyles.colors.gray300,
  skillsCodeBoxShadow: `1px 1px 2px rgba(15, 82, 131, 0.3), -1px -1px 2px rgba(9, 50, 81, 0.5), inset -1px 1px 2px rgba(9, 50, 81, 0.2), inset 1px -1px 2px rgba(9, 50, 81, 0.2), inset -1px -1px 2px rgba(15, 82, 131, 0.9), inset 1px 1px 3px rgba(9, 50, 81, 0.9)`,
  skillsDesignBackground: sharedStyles.colors.indigo900,
  skillsDesignText: sharedStyles.colors.gray300,
  skillsDesignBoxShadow: `1px 1px 2px rgba(57, 61, 95, 0.3), -1px -1px 2px rgba(35, 37, 59, 0.5), inset -1px 1px 2px rgba(35, 37, 59, 0.2), inset 1px -1px 2px rgba(35, 37, 59, 0.2), inset -1px -1px 2px rgba(57, 61, 95, 0.9), inset 1px 1px 3px rgba(35, 37, 59, 0.9)`,

  /* portfolio */
  portfolioCode: sharedStyles.colors.blue400,
  portfolioDesign: sharedStyles.colors.indigo400,

  /* socmed logos */
  default: sharedStyles.colors.gray50,
  facebook: sharedStyles.colors.gray50,
  twitter: "#55ACEE",
  linkedin: sharedStyles.colors.gray50,
  messenger: "#00B9FF",
  skype: "#00AFF0",
  dribbble: "#ea4c89",

  /* tech logos */
  html: "#E44D26",
  css: "#2965F1",
  javascript: "#F7DF1E",
  react: "#00D8FF",
  npm: "#D40001",

  /* box shadows */
  boxShadowEmbossed: sharedStyles.boxShadows.darkEmbossed,
  boxShadowEngraved: sharedStyles.boxShadows.darkEngraved,
  boxShadowBody: sharedStyles.boxShadows.darkBoxShadow,
};
