import React from "react";

export const JiroLogo = () => {
  return (
    <svg width="29" height="23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M14.085 6.9c-1.94 2.605-2.341 7.5-2.341 14.7 0-.6 0 .6 1.17 0 4.248-9.863 2.712-13.459 2.488-14.7h-1.316z"
        fill="#2194A6"
      />
      <path
        d="M9.763 7.617v2.602l-6.947 3.253-2.78 1.301v-2.602l9.727-4.554z"
        fill="#6E7F81"
      />
      <path
        d="M2.816 13.472l-2.78 1.301 9.727 4.555v-2.603l-6.947-3.253zM25.047 13.472L18.1 10.22V7.617l9.726 4.554v2.602L18.1 19.328v-2.603l6.947-3.253z"
        fill="#6E7F81"
      />
      <path
        d="M15.841 6c1.281-.328 1.171-1.8.586-2.4-.585-1.2 0-1.2.585-1.2-.585-.6-1.474-.377-1.474-.377C14.25 2.312 13.5 3.6 13.5 4.2c0 .105 0 2.4 2.341 1.8z"
        fill="#2194A6"
      />
    </svg>
  );
};
