import React from "react";
import styled from "styled-components";

import { device } from "../../device";
import avatar from "../../assets/images/thumbnails/avatar-2.jpg";

export const Avatar = () => {
  return (
    <Border>
      <Container>
        <Picture />
      </Container>
    </Border>
  );
};

const Border = styled.div`
  position: absolute;
  top: -48px;
  left: 50%;

  padding: 16px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  border-radius: 50%;

  transform: translateX(-50%);

  @media ${device.laptop} {
    left: -48px;
    top: 50%;

    transform: translate(0, -50%);
  }
`;

const Container = styled.div`
  padding: 8px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 50%;
`;

const Picture = styled.div`
  width: 88px;
  height: 88px;

  background-image: url(${avatar});
  background-size: 100% 100%;
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 50%;
`;
