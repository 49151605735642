import React from "react";
import styled from "styled-components";

import { sharedStyles as Styles } from "../Themes";
import { device } from "../../device";

export const PageTitle = (props) => {
  return (
    <Container>
      <Separator />
      {props.children}
      <Separator />
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-evenly;
  align-items: center;
  align-self: center;
  width: 144px;
  color: ${(props) => props.theme.pageTitle};
  margin: 0 0 32px 0;

  font-size: ${Styles.fontSizes.sm};
  font-weight: ${Styles.fontWeight.bold};
  line-height: 1.5;
  text-transform: uppercase;
  text-align: center;

  @media ${device.tablet} {
    display: none;
  }
`;

const Separator = styled.div`
  height: 16px;
  width: 8px;
  border-radius: 8px;

  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
`;
