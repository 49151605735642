import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { IconContext } from "react-icons";
import { FaKeyboard, FaPaintBrush } from "react-icons/fa";

export const CardCategoryIcon = (props) => {
  const themeContext = useContext(ThemeContext);

  return (
    <Border {...props}>
      <Container>
        {props.category === "design" ? (
          <IconContext.Provider
            value={{ color: themeContext.portfolioDesign, size: "16px" }}
          >
            <FaPaintBrush />
          </IconContext.Provider>
        ) : props.category === "code" ? (
          <IconContext.Provider
            value={{ color: themeContext.portfolioCode, size: "16px" }}
          >
            <FaKeyboard />
          </IconContext.Provider>
        ) : (
          <IconContext.Provider
            value={{ color: themeContext.portfolioCode, size: "16px" }}
          >
            <FaKeyboard />
          </IconContext.Provider>
        )}
      </Container>
    </Border>
  );
};

const Border = styled.div`
  position: absolute;
  top: -4px;
  left: -8px;

  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${(props) => props.theme.boxShadowEngraved};
  background-color: ${(props) => props.theme.body};
  border-radius: 50%;
  padding: 4px;
  max-width: 24px;
  max-height: 24px;

  animation: ${(props) =>
    props.moveIcon
      ? "moveTopToBottom 300ms ease-in forwards"
      : `moveBottomToTop 300ms ease-in forwards`};

  @keyframes moveTopToBottom {
    from {
      transform: translateY(-8px);
      opacity: 1;
    }

    to {
      transform: translateY(20px);
      opacity: 1;
    }
  }

  @keyframes moveBottomToTop {
    from {
      transform: translateY(20px);
      opacity: 1;
    }

    to {
      transform: translateY(-8px);
      opacity: 1;
    }
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  background-color: ${(props) => props.theme.body};
  border-radius: 50%;
  padding: 4px;
`;
