import { createGlobalStyle } from "styled-components";
import "sanitize.css";
import { device } from "./device";

export const GlobalStyle = createGlobalStyle`
    * {
    box-sizing: border-box;
    }

    *::-webkit-scrollbar {
    width: 8px;
    height: 8px;

    }

    *::-webkit-scrollbar-track {
    background: ${(props) => props.theme.scrollbarTrack};

    }
    
    /* Handle */
    *::-webkit-scrollbar-thumb {
        background: ${(props) => props.theme.scrollbarThumb};
    }

    /* Handle on hover */
    *::-webkit-scrollbar-thumb:hover {
        background: ${(props) => props.theme.scrollbarThumbHover};
    }

    body {
        width: 100%;
        min-height: 100vh;
        background-image: url(${(props) => props.theme.texture});
        background-color: ${(props) => props.theme.body};
        font-family: "Rubik", sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        height: auto;

        @media ${device.laptop} {
            display: flex;
            justify-content: center;
            align-items:center;

            height: 100vh;
            width: 100vw;
        }
    }

    button {
        font-family: "Rubik", sans-serif;
    }


`;
