import React from "react";
import styled from "styled-components";

import { device } from "../device";

export const PageBorder = (props) => {
  return <Container>{props.children}</Container>;
};

const Container = styled.div`
  width: inherit;
  height: inherit;
  padding: 40px 16px 0 16px;

  @media ${device.laptop} {
    padding: 40px 40px 0 40px;
  }

  & > :first-child {
    margin-bottom: 40px;
  }

  @media ${device.desktopM} {
    padding: 40px 80px 0 80px;
  }
`;
