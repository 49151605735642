import React, { useContext } from "react";
import styled, { ThemeContext } from "styled-components";
import { NavLink } from "react-router-dom";

import { device } from "../../../device";

export const DesktopMenu = () => {
  const themeContext = useContext(ThemeContext);

  const defaultStyle = {
    display: "inline-block",
    borderRadius: "8px",
    padding: "12px 24px",
    backgroundColor: themeContext.body,
    color: themeContext.buttonText,
    boxShadow: themeContext.boxShadowEmbossed,
    border: "none",
    outline: "none",
    maxHeight: "56px",

    fontSize: "0.875rem",
    fontWeight: "700",
    textAlign: "center",
    textDecoration: "none",
    textTransform: "uppercase",

    cursor: "pointer",
  };

  const isActive = {
    position: "relative",
    boxShadow: themeContext.boxShadowEngraved,
    color: themeContext.buttonTextActive,
    top: "2px",
  };

  return (
    <Navigation>
      <NavLink to="/" exact style={defaultStyle} activeStyle={isActive}>
        PROFILE
      </NavLink>
      <NavLink to="/about" style={defaultStyle} activeStyle={isActive}>
        ABOUT
      </NavLink>
      <NavLink to="/portfolio" style={defaultStyle} activeStyle={isActive}>
        PORTFOLIO
      </NavLink>
      <NavLink to="/contact" style={defaultStyle} activeStyle={isActive}>
        CONTACT
      </NavLink>
    </Navigation>
  );
};

const Navigation = styled.nav`
  display: none;

  @media ${device.tablet} {
    display: flex;
    flex-flow: row nowrap;
    border-radius: 8px;

    & > * {
      margin-bottom: 0px;
      margin-right: 12px;
      min-width: 120px;
    }

    & > *:last-child {
      margin-right: 0;
    }
  }

  @media ${device.laptop} {
    padding: 16px 32px;

    & > * {
      margin-bottom: 0px;
      margin-right: 24px;
    }
  }
`;
