import React from "react";
import styled from "styled-components";

export const HtmlLogo = (props) => {
  return (
    <Icon
      width="12"
      height="16"
      viewBox="0 0 12 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M11.6535 3.13644L10.6234 14.6759L5.99317 15.9596L1.37569 14.6777L0.34668 3.13644H11.6535Z"
          fill="#E44D26"
        />
        <path
          d="M6 14.9784L9.74147 13.9411L10.6217 4.08008H6V14.9784Z"
          fill="#F16529"
        />
        <path
          d="M3.99763 6.91113H6.00007V5.49564H2.45068L2.48455 5.87538L2.83247 9.77611H6.00007V8.36061H4.127L3.99763 6.91113Z"
          fill="#EBEBEB"
        />
        <path
          d="M4.3167 10.4839H2.89575L3.09405 12.7064L5.9936 13.5113L6.00011 13.5095V12.0368L5.99391 12.0385L4.41748 11.6128L4.3167 10.4839Z"
          fill="#EBEBEB"
        />
        <path
          d="M1.39868 0H2.11802V0.710692H2.77606V0H3.49543V2.15215H2.7761V1.43149H2.11806V2.15215H1.39873V0H1.39868Z"
          fill="#F1F3F3"
        />
        <path
          d="M4.44128 0.713706H3.80811V0H5.79432V0.713706H5.16075V2.15215H4.44141V0.713706H4.44137H4.44128Z"
          fill="#F1F3F3"
        />
        <path
          d="M6.10962 0H6.85967L7.32105 0.75621L7.782 0H8.53235V2.15215H7.81595V1.08543L7.32105 1.85064H7.30864L6.81344 1.08543V2.15215H6.10962V0Z"
          fill="#F1F3F3"
        />
        <path
          d="M8.89018 0H9.60974V1.4408H10.6213V2.15215H8.89014V0H8.89018Z"
          fill="#F1F3F3"
        />
        <path
          d="M5.99516 9.77608H7.73818L7.57384 11.6119L5.99512 12.038V13.5106L8.89696 12.7064L8.91824 12.4672L9.25091 8.74069L9.28544 8.3606H5.99516V9.77608Z"
          fill="white"
        />
        <path
          d="M5.99512 6.90766V6.91112H9.4142L9.44256 6.59298L9.5071 5.87538L9.54091 5.49564H5.99512V6.90771V6.90766Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="11.3463"
            height="16"
            fill="white"
            transform="translate(0.326904)"
          />
        </clipPath>
      </defs>
    </Icon>
  );
};

const Icon = styled.svg``;
