import React from "react";
import styled from "styled-components";

export const AppBody = (props) => {
  return <Container>{props.children}</Container>;
};

const Container = styled.div`
  position: relative;

  width: 100%;
  height: 100%;
  background-color: ${(props) => props.theme.body};

  box-shadow: ${(props) => props.theme.boxShadowBody};
  border-radius: 8px;

  overflow-y: hidden;
`;
