import React from "react";
import styled from "styled-components";

export const JavascriptLogo = () => {
  return (
    <Icon width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0 0h16v16H0V0z" fill="#F7DF1E" />
      <path
        d="M4.207 13.37l1.224-.74c.237.419.452.773.967.773.494 0 .806-.193.806-.945V7.346h1.503v5.133c0 1.558-.913 2.266-2.245 2.266-1.202 0-1.9-.622-2.255-1.374M9.524 13.21l1.224-.71c.323.527.741.914 1.482.914.623 0 1.02-.312 1.02-.741 0-.516-.407-.698-1.095-1l-.376-.16c-1.084-.462-1.804-1.042-1.804-2.266 0-1.128.86-1.987 2.202-1.987.956 0 1.643.333 2.137 1.202l-1.17.752c-.258-.461-.538-.644-.967-.644-.44 0-.72.28-.72.644 0 .451.28.634.924.913l.376.161c1.278.548 1.997 1.107 1.997 2.363 0 1.354-1.063 2.095-2.491 2.095-1.397 0-2.299-.666-2.74-1.536"
        fill="#363E3F"
      />
    </Icon>
  );
};

const Icon = styled.svg``;
