import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { device } from "../../device";

import { Button } from "../../components/Button";
import { PageTitle } from "../../components/PageTitle";
import { ShortIntro } from "./ShortIntro/ShortIntro";
import { Skills } from "../../components/Skills/Skills";
import { History } from "../../components/History/History";
import { PageBorder } from "../";

export const AboutPage = () => {
  return (
    <PageBorder>
      <Container>
        <PageTitle>about</PageTitle>
        <LeftContainer>
          <ShortIntro />
          <HistoryContainer>
            <History variant="career" />
            <History variant="education" />
          </HistoryContainer>
          <DesktopButtonContainer>
            <Link to="/contact">
              <Button>Hire Me</Button>
            </Link>
            <Link to="/portfolio">
              <Button>Portfolio</Button>
            </Link>
          </DesktopButtonContainer>
        </LeftContainer>
        <RightContainer>
          <Skills variant="code" />
          <Skills variant="design" />
          <MobileButtonContainer>
            <Link to="/contact">
              <Button>Hire Me</Button>
            </Link>
            <Link to="/portfolio">
              <Button>Portfolio</Button>
            </Link>
          </MobileButtonContainer>
        </RightContainer>
      </Container>
    </PageBorder>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > :first-child {
    margin-bottom: 24px;
  }

  @media ${device.tablet} {
    flex-flow: row wrap;
    justify-content: space-between;
  }
`;

const LeftContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > * {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @media ${device.tablet} {
    justify-content: space-between;
    align-items: flex-start;
    flex-flow: row wrap;
    width: 62.5%;
    margin-bottom: 40px;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;

  & > :first-child {
    margin-bottom: 24px;
  }

  @media ${device.tablet} {
    max-width: 32.5%;
    margin-bottom: 40px;
  }
`;

const HistoryContainer = styled.div`
  display: flex;
  flex-flow: column;

  & > :first-child {
    margin-bottom: 16px;
  }

  @media ${device.tablet} {
    flex-flow: row;
    justify-content: space-between;
  }

  @media ${device.laptop} {
    flex-flow: row;
    width: 90%;
  }
`;

const DesktopButtonContainer = styled.div`
  display: none;

  @media ${device.tablet} {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;

    & > * {
      width: 40%;
    }

    & button {
      width: 100%;
    }
  }
`;

const MobileButtonContainer = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
  margin-top: 24px;
  margin-bottom: 40px;

  & > * button {
    width: 100%;
  }

  & > :first-child {
    margin-bottom: 16px;
  }

  @media ${device.tablet} {
    display: none;
  }
`;
