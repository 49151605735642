import React from "react";
import styled from "styled-components";

export const NavModal = (props) => {
  return <StyledModal {...props} />;
};

const StyledModal = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1000;

  display: ${(props) => (props.modalDisplay ? "block" : "none")};
  height: 100vh;
  width: 100vw;
  background-color: ${(props) => props.theme.modal};
  opacity: 0.75;
`;
