import React from "react";
import styled from "styled-components";

export const CardTechUsedIcons = (props) => {
  return <Container>{props.children}</Container>;
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  padding: 2px;
  background-color: ${(props) => props.theme.body};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 8px;
`;
