import React from "react";
import styled from "styled-components";

import { ContentBorder } from "../../";
import { Text } from "../../../components/Text";
import { ContentTitle } from "../../../components/ContentTitle";

import { device } from "../../../device";

export const ShortIntro = () => {
  return (
    <Container>
      <ContentTitle text="Short Intro" icon="about" />
      <ContentBorder>
        <Limiter>
          <Text variant="bodyText" size="sm">
            Hi! I'm Jiro, a Software Engineer and an aspiring UX/UI Designer.
            <br />
            <br />
            Started my journey as a Web Builder/Virtual Assistant, became an
            aspiring Frontend Web Developer, and currently working as a Software
            Engineer. Throughout my tenure, I've gained knowledge from the
            plethora of technologies I've dealt with across my work experiences.
            <br />
            <br />
            I'm a firm believer of developing a passion for learning. Regardless
            of my tenure, I'll always be keen to discover, try and build new
            cool stuffs!
            <br />
            <br />
            Enough about me, let's talk about us working together, shall we? Got
            a design and functionality in mind? Look no further, I can make your
            concepts into reality with splendid accuracy!
            <br />
            <br />
            Please do contact me and let's talk business!
          </Text>
        </Limiter>
      </ContentBorder>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-flow: column;
  width: 100%;
`;

const Limiter = styled.div`
  overflow-y: auto;

  margin-right: 0 !important;
  border-radius: 8px;

  & > * {
    padding: 12px 16px;
  }

  & > :first-child {
    line-height: 1.75;
  }

  @media ${device.tablet} {
    max-height: 200px;
  }
`;
