import React from "react";
import styled from "styled-components";

export const PhotoshopLogo = () => {
  return (
    <Icon width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12.05 1H4.418v14h10.456V3.944l-2.79-2.908L12.05 1z"
        fill="#070444"
      />
      <path
        d="M1.126 1.746H11.28v3.922H1.126V1.746zM12.05 1v2.944h2.824L12.05 1z"
        fill="#5BC3F6"
      />
      <path
        d="M3.55 2.494a3 3 0 01.621-.05.788.788 0 01.594.196.857.857 0 01.214.603.973.973 0 01-.179.613.783.783 0 01-.627.259c-.041 0-.082-.003-.123-.007v.85h-.5V2.494zm.5 1.103a.806.806 0 00.114.007.31.31 0 00.32-.35.273.273 0 00-.284-.309.583.583 0 00-.15.015v.637zM5.541 4.289c.145.097.314.15.488.156.172 0 .256-.078.256-.204 0-.125-.085-.191-.295-.291a.797.797 0 01-.527-.75.727.727 0 01.75-.781.962.962 0 01.51.13l-.103.533a.788.788 0 00-.41-.118c-.153 0-.237.074-.237.185 0 .126.1.177.337.296a.771.771 0 01.49.75.747.747 0 01-.8.8c-.193 0-.383-.055-.55-.156l.091-.55zM7.81 4.49h-.05V2.877h.007a.558.558 0 01.315.057.684.684 0 01.245.487c.005.116 0 .209 0 .29.002.09-.004.18-.018.269a.923.923 0 01-.089.265.594.594 0 01-.191.189.354.354 0 01-.218.055l-.001.001zm.097-2.071c-.059 0-.115.005-.155.006l-.124.004h-.41v2.526H7.7c.185.006.368-.026.541-.091a.9.9 0 00.359-.259c.093-.116.16-.25.196-.394.041-.163.062-.331.06-.5a2.322 2.322 0 00-.046-.593.934.934 0 00-.494-.596 1.268 1.268 0 00-.288-.094.675.675 0 00-.12-.01"
        fill="#070444"
      />
      <path
        d="M8 9.825a3.61 3.61 0 01-.45-.015V8.065c.056-.008.25-.015.52-.015.65 0 .937.272.937.895 0 .778-.458.88-1.006.88H8zm.07-2.515c-.527 0-1.047.008-1.311.016-.034 0-.041.015-.041.054v4.965c0 .039.014.054.05.054H7.5c.035 0 .05-.015.05-.062v-1.772h.43c1.02 0 1.859-.329 1.859-1.566C9.832 8.097 9.43 7.31 8.07 7.31zM11.537 8.609a1.133 1.133 0 00-1.269 1.128c0 .569.25.872.964 1.2.5.226.625.343.625.545 0 .171-.111.35-.43.35-.409-.024-.8-.17-1.124-.42a.028.028 0 00-.017-.007c-.016 0-.031.017-.031.046v.715a.08.08 0 00.041.079c.322.202.695.308 1.075.303a1.16 1.16 0 001.297-1.2c0-.56-.305-.85-1.02-1.175-.52-.234-.61-.335-.61-.522 0-.15.09-.327.43-.327a1.9 1.9 0 01.964.296.047.047 0 00.026.009c.021 0 .037-.022.037-.056v-.637a.113.113 0 00-.042-.1 1.682 1.682 0 00-.915-.226"
        fill="#5BC3F6"
      />
    </Icon>
  );
};

const Icon = styled.svg``;
