import React from "react";
import styled from "styled-components";

import { device } from "../../device";

export const Filter = (props) => {
  return (
    <Container>
      <Border>
        <Content>
          <Button>
            <div>&nbsp;</div>
            <Radio
              type="radio"
              id="all"
              name="filter"
              value="all"
              defaultChecked={true}
              onClick={(e) => props.setPortfolioFilter(e.target.value)}
            />
            <Label htmlFor="all">ALL</Label>
          </Button>

          <Button>
            <div>&nbsp;</div>
            <Radio
              type="radio"
              id="code"
              name="filter"
              value="code"
              onClick={(e) => props.setPortfolioFilter(e.target.value)}
            />
            <Label htmlFor="code">CODE</Label>
          </Button>

          <Button>
            <div>&nbsp;</div>
            <Radio
              type="radio"
              id="design"
              name="filter"
              value="design"
              onClick={(e) => props.setPortfolioFilter(e.target.value)}
            />
            <Label htmlFor="design">DESIGN</Label>
          </Button>
        </Content>
      </Border>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;

  width: 100%;
  margin: 0 auto 24px auto;

  @media ${device.tablet} {
    box-shadow: ${(props) => props.theme.boxShadowEngraved};
    max-width: 360px;
    padding: 16px 64px;
  }
`;

const Border = styled.div`
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border-radius: 8px;
  padding: 8px;

  min-width: 296px;
  max-width: 296px;
  max-height: 56px;
`;

const Content = styled.div`
  display: flex;
  flex-flow: row;
  justify-content: space-around;
  align-items: center;
  border-radius: 8px;
  overflow: hidden;

  & > * {
    border-radius: 0;
    box-shadow: none;
  }
`;

const Button = styled.div`
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 16px;
  min-width: 88px;

  & > * {
    font-weight: 700;
  }

  cursor: pointer;

  & > input[type="radio"]:checked + label {
    box-shadow: ${(props) => props.theme.boxShadowEngraved};
    color: ${(props) => props.theme.buttonTextActive};
  }
`;

const Radio = styled.input`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: block;
  opacity: 0.001;
  z-index: 50;
`;

const Label = styled.label`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 75;

  display: flex;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;
  color: ${(props) => props.theme.buttonText};
  font-weight: 700;

  cursor: pointer;
`;
