import React from "react";
import styled from "styled-components";

export const ReactLogo = () => {
  return (
    <Icon width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8 9.427a1.427 1.427 0 100-2.853 1.427 1.427 0 000 2.853zM4.006 10.837l-.316-.08C1.345 10.163 0 9.156 0 7.996c0-1.16 1.346-2.167 3.69-2.759l.316-.08.089.313c.237.818.54 1.615.907 2.384l.068.142-.068.142c-.367.77-.672 1.568-.907 2.386l-.09.312zm-.461-4.872C1.763 6.467.669 7.233.669 7.997c0 .763 1.093 1.53 2.876 2.03.218-.693.482-1.372.787-2.03a16.27 16.27 0 01-.787-2.032zm8.45 4.872l-.089-.313a15.692 15.692 0 00-.908-2.385l-.068-.142.068-.143c.367-.768.67-1.566.908-2.385l.088-.312.316.08C14.656 5.83 16 6.836 16 7.997c0 1.16-1.345 2.167-3.69 2.76l-.316.08zm-.329-2.84c.32.692.586 1.373.79 2.03 1.782-.5 2.876-1.267 2.876-2.03 0-.764-1.094-1.529-2.877-2.032a16.528 16.528 0 01-.789 2.032z"
        fill="#00D8FF"
      />
      <path
        d="M3.54 5.962l-.088-.311c-.66-2.325-.46-3.99.549-4.57.987-.571 2.575.104 4.238 1.81l.227.234-.227.233a15.76 15.76 0 00-1.614 1.977l-.09.13-.157.012c-.85.067-1.695.203-2.523.407l-.315.078zm1.264-4.419a.932.932 0 00-.47.116c-.662.381-.78 1.709-.323 3.5.712-.157 1.43-.27 2.154-.332.42-.595.877-1.162 1.37-1.697-1.042-1.013-2.025-1.587-2.73-1.587zm6.392 13.575c-.95 0-2.17-.716-3.435-2.016l-.227-.233.227-.231a15.85 15.85 0 001.615-1.98l.09-.128.154-.015a15.566 15.566 0 002.524-.405l.316-.08.089.312c.657 2.323.458 3.99-.55 4.57-.245.139-.522.21-.803.206zm-2.73-2.254c1.04 1.012 2.025 1.587 2.73 1.587.18 0 .338-.04.47-.116.663-.382.78-1.712.323-3.504-.711.159-1.43.272-2.156.335a16.414 16.414 0 01-1.367 1.698z"
        fill="#00D8FF"
      />
      <path
        d="M12.46 5.962l-.316-.079a15.565 15.565 0 00-2.524-.407l-.155-.013-.09-.13A15.626 15.626 0 007.76 3.356l-.226-.233.226-.231C9.423 1.186 11.01.51 12 1.082c1.008.58 1.21 2.245.55 4.569l-.089.311zM9.833 4.827c.762.067 1.485.182 2.156.333.457-1.792.34-3.12-.323-3.502-.658-.38-1.898.203-3.2 1.472.493.535.95 1.101 1.367 1.697zm-5.029 10.29a1.585 1.585 0 01-.803-.205c-1.01-.58-1.21-2.247-.55-4.57l.086-.312.316.08c.77.194 1.62.33 2.524.405l.155.015.09.128a15.7 15.7 0 001.615 1.98l.227.231-.227.233c-1.265 1.3-2.485 2.016-3.433 2.016zm-.794-4.285c-.455 1.792-.338 3.122.323 3.504.66.376 1.895-.204 3.201-1.472a16.916 16.916 0 01-1.369-1.697 16.54 16.54 0 01-2.155-.335z"
        fill="#00D8FF"
      />
      <path
        d="M8 11.252c-.549 0-1.112-.024-1.678-.07l-.157-.014-.09-.13c-.32-.459-.62-.93-.9-1.414-.279-.484-.537-.98-.776-1.485l-.067-.142.067-.143c.239-.505.497-1 .776-1.485.277-.476.58-.953.9-1.415l.09-.127.157-.015a20.1 20.1 0 013.355 0l.155.015.09.128c.64.918 1.201 1.888 1.679 2.9l.066.143-.066.141a19.776 19.776 0 01-1.679 2.9l-.09.13-.155.013c-.564.046-1.128.07-1.677.07zm-1.465-.723c.987.075 1.944.075 2.93 0a19.56 19.56 0 001.465-2.532 19.12 19.12 0 00-1.465-2.532 19.083 19.083 0 00-2.93 0A18.975 18.975 0 005.07 7.997c.425.879.915 1.725 1.465 2.532z"
        fill="#00D8FF"
      />
    </Icon>
  );
};

const Icon = styled.svg``;
