import React from "react";
import styled from "styled-components";

export const VscodeLogo = () => {
  return (
    <Icon width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask
        id="prefix__a"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="16"
        height="16"
      >
        <path
          d="M11.302 15.913a.986.986 0 00.79-.03l3.282-1.592a.999.999 0 00.564-.905V2.596a1.009 1.009 0 00-.564-.905L12.092.1a.985.985 0 00-1.132.194L4.68 6.07 1.942 3.977a.66.66 0 00-.847.038l-.878.804a.673.673 0 000 .99l2.372 2.182-2.373 2.182a.673.673 0 00.001.99l.878.804a.66.66 0 00.847.038L4.68 9.912l6.281 5.776a.991.991 0 00.342.225zm.654-11.569L7.189 7.991l4.767 3.646V4.344z"
          fill="#fff"
        />
      </mask>
      <g mask="url(#prefix__a)">
        <path
          d="M15.383 1.693L12.1.099a.986.986 0 00-1.133.194l-10.75 9.88a.673.673 0 000 .989l.878.804a.661.661 0 00.849.039l12.945-9.899a.66.66 0 011.058.53v-.039a1.01 1.01 0 00-.564-.904z"
          fill="#0065A9"
        />
        <path
          d="M15.383 14.288L12.1 15.88a.986.986 0 01-1.133-.194L.217 5.808a.673.673 0 010-.99l.878-.804a.661.661 0 01.849-.038l12.945 9.898a.66.66 0 001.058-.53v.04a1.01 1.01 0 01-.564.904z"
          fill="#007ACC"
        />
        <path
          d="M12.09 15.882a.986.986 0 01-1.133-.194c.367.37.996.108.996-.416V.71a.583.583 0 00-.996-.416A.99.99 0 0112.088.1l3.284 1.591a.999.999 0 01.564.905v10.79c0 .386-.22.738-.564.905l-3.284 1.591z"
          fill="#1F9CF0"
        />
        <path
          d="M11.302 15.912a.984.984 0 00.79-.03l3.282-1.592a.999.999 0 00.564-.904V2.596a1.009 1.009 0 00-.564-.906L12.092.1a.985.985 0 00-1.132.194L4.68 6.07 1.942 3.976a.66.66 0 00-.847.038l-.878.805a.673.673 0 000 .99L2.588 7.99.216 10.172a.673.673 0 00.001.99l.878.805a.66.66 0 00.847.038L4.68 9.91l6.281 5.776a.99.99 0 00.342.225zm.654-11.568L7.189 7.99l4.767 3.647V4.344z"
          fill="url(#prefix__paint0_linear)"
          fillOpacity=".25"
        />
      </g>
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1="7.969"
          y1="0"
          x2="7.969"
          y2="15.98"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#fff" />
          <stop offset="1" stopColor="#fff" stopOpacity="0" />
        </linearGradient>
      </defs>
    </Icon>
  );
};

const Icon = styled.svg``;
