import React, { useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";

import { device } from "../device";

export const PageContainer = (props) => {
  const { pathname } = useLocation();
  const el = useRef(null);

  useLayoutEffect(() => {
    el.current.scrollTo(0, 0);
  }, [pathname]);

  return <Container ref={el}>{props.children}</Container>;
};

const Container = styled.div`
  position: relative;
  z-index: 1;

  width: 100%;
  height: 100%;
  overflow-y: auto;
  max-height: inherit;

  @media ${device.desktopM} {
    max-height: 775px;
  }
`;
