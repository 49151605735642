import React from "react";
import styled from "styled-components";

export const FigmaLogo = () => {
  return (
    <Icon width="12" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.667 10.667a2.667 2.667 0 100-5.334 2.667 2.667 0 000 5.334z"
        fill="#19BCFE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 16A2.667 2.667 0 006 13.333v-2.666H3.333a2.667 2.667 0 100 5.333z"
        fill="#09CF83"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 10.667H6V5.333H3.333a2.667 2.667 0 100 5.334z"
        fill="#A259FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.333 5.333H6V0H3.333a2.667 2.667 0 100 5.333z"
        fill="#F24E1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.667 5.333H6V0h2.667a2.667 2.667 0 010 5.333z"
        fill="#FF7262"
      />
    </Icon>
  );
};

const Icon = styled.svg``;
