import React from "react";
import styled from "styled-components";
import { sharedStyles } from "../Themes/";

export const NavButton = (props) => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};

const StyledButton = styled.button`
  position: relative;

  display: inline-block;
  border-radius: 8px;
  padding: 16px 24px;
  background-color: ${(props) => props.theme.body};
  color: ${(props) => props.theme.buttonText};
  box-shadow: ${(props) => props.theme.boxShadowEmbossed};
  border: none;
  outline: none;
  max-height: 3.5rem;

  font-size: ${sharedStyles.fontSizes.md};
  font-weight: ${sharedStyles.fontWeight.bold};
  text-align: center;
  text-transform: uppercase;
  text-decoration: none;

  cursor: pointer;
`;
