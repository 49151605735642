import React from "react";
import styled from "styled-components";

export const CssLogo = () => {
  return (
    <Icon width="12" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#prefix__clip0)">
        <path
          d="M5.993 15.96l-4.617-1.282L.346 3.136h11.307l-1.03 11.54-4.63 1.284z"
          fill="#264DE4"
        />
        <path d="M9.741 13.941l.88-9.86H6v10.897l3.741-1.037z" fill="#2965F1" />
        <path
          d="M2.706 8.36l.126 1.416H6V8.361H2.706zM2.45 5.496l.13 1.415H6V5.496H2.45zM6 12.037l-.006.002-1.577-.426-.1-1.13H2.896l.198 2.223 2.9.805L6 13.51v-1.472z"
          fill="#EBEBEB"
        />
        <path
          d="M3.008 0h1.714v.717h-.997v.717h.997v.717H3.008V0zM5.065 0h1.714v.623h-.997v.125h.997v1.434H5.065v-.655h.997v-.124h-.997V0zM7.122 0h1.714v.623H7.84v.125h.997v1.434H7.122v-.655h.998v-.124h-.998V0z"
          fill="#F1F3F3"
        />
        <path
          d="M9.285 8.36l.256-2.864H5.995V6.91H7.99l-.13 1.45H5.996v1.415h1.743l-.164 1.836-1.579.426v1.473l2.902-.805.021-.239.333-3.726.034-.38z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" transform="translate(.327)" d="M0 0h11.346v16H0z" />
        </clipPath>
      </defs>
    </Icon>
  );
};

const Icon = styled.svg``;
