import React, { useState } from "react";
import styled from "styled-components";

import { CardContent, CardCover } from "./";

export const Card = (props) => {
  const [isOpened, setIsOpened] = useState(null);

  return (
    <Container opened={isOpened === true ? 1 : isOpened === false ? 0 : null}>
      <BoundarySetter>
        <CardCover
          opened={isOpened}
          buttonclicked={() => {
            setIsOpened(!isOpened);
          }}
          title={props.title}
          image={props.image}
          imageClicked={props.imageClicked}
          category={props.category}
        />

        <CardContent
          details={props.details}
          htmlLogo={props.htmlLogo}
          cssLogo={props.cssLogo}
          jsLogo={props.jsLogo}
          reactLogo={props.reactLogo}
          sassLogo={props.sassLogo}
          figmaLogo={props.figmaLogo}
          psLogo={props.psLogo}
          npmLogo={props.npmLogo}
          vsLogo={props.vsLogo}
          url={props.url}
        ></CardContent>
      </BoundarySetter>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  z-index: 1500;

  border-radius: 8px;

  height: 320px;
  width: 256px;
  min-width: 256px;
  max-width: 256px;
  min-height: 280px;
  max-height: 280px;

  background-color: ${(props) => props.theme.body};

  margin: 16px;

  &::after {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;

    content: "";
    width: 100%;
    height: 100%;
    border-radius: 8px;
    box-shadow: ${(props) => props.theme.boxShadowEmbossed};

    animation: ${(props) =>
      props.opened === 1
        ? "moveShadowToTop 350ms ease-in-out forwards"
        : props.opened === 0
        ? "moveShadowToBottom 350ms ease-in-out forwards"
        : null};
  }

  @keyframes moveShadowToTop {
    from {
      height: 100%;
      top: 0;
    }

    to {
      height: 72px;
      top: 0;
    }
  }

  @keyframes moveShadowToBottom {
    from {
      height: 72px;
      top: 0;
    }

    to {
      height: 100%;
      top: 0;
    }
  }
`;

const BoundarySetter = styled.div`
  position: relative;

  border-radius: 8px;
  width: 100%;
  height: 100%;

  overflow-y: hidden;
`;
